<script>
    import { Button, Card, Spinner } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { fetchFiles, getUser } from "../service/http_calls.js";
    import {
        authenticated_user,
        current_user,
        files_list
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";

    let loading = false;
    let loading_ingest = false;
    let errorMessage = ""; // New state variable for the error message

    let general_prompt_edit = false;

    onMount(async () => {
        const resp = await fetchFiles("learn");
        files_list.update( (prev) => {
            prev["learn"] = resp;
            return prev;
        });
        
        });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="learn"></FilesTable>

            <div class="flex justify-end my-5">
                <Button
                    size="sm"
                    color="alternative"
                    class="mr-5"
                    on:click={() => {
                        general_prompt_edit = true;
                    }}>Add Instructions</Button
                >

                {#if loading_ingest}
                    <Spinner></Spinner>
                {:else}
                    <Button
                        size="sm"
                        color="purple">Submit</Button
                    >
                {/if}
            </div>
        </Card>
    </div>
</div>
