<script>
    import {
        Accordion,
        Button,
        Card,
        P,
        Span,
        Table,
        TableBody,
        TableBodyCell,
        TableBodyRow,
        TableHead,
        TableHeadCell,
    } from "flowbite-svelte";
    import {
        BadgeCheckSolid,
        EyeSolid,
        TrashBinSolid,
    } from "flowbite-svelte-icons";
    import { questions, selected_file_id } from "../service/store";
    import SingleQuestion from "./SingleQuestion.svelte";
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <div class="mb-10">
                <Table striped={true} class="w-full table-fixed mt-5">
                    <TableHead
                        class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400"
                    >
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell class="w-1/6  text-center"
                            >Email</TableHeadCell
                        >
                        <TableHeadCell class="w-1/6  text-center"
                            >Access</TableHeadCell
                        >
                        <TableHeadCell class="w-1/6 text-center"
                            >Invitation accepted</TableHeadCell
                        >
                        <TableHeadCell class="w-1/6 text-center"
                            >Review completed</TableHeadCell
                        >
                    </TableHead>
                    <TableBody tableBodyClass="divide-y">
                        <TableBodyRow>
                            <TableBodyCell
                                class="truncate overflow-hidden text-ellipsis"
                                >Joe</TableBodyCell
                            >
                            <TableBodyCell class="text-right pr-10"
                                >Joe@123.com</TableBodyCell
                            >
                            <TableBodyCell class="text-right pr-10"
                                >View, Edit, Comment</TableBodyCell
                            >
                            <TableBodyCell
                                class="text-center justify-center"
                                ><BadgeCheckSolid color="green"
                                ></BadgeCheckSolid></TableBodyCell
                            >
                            <TableBodyCell
                                class="text-center justify-center"
                                ><BadgeCheckSolid color="green"
                                ></BadgeCheckSolid></TableBodyCell
                            >
                        </TableBodyRow>
                    </TableBody>
                </Table>

                <P size="lg" class="my-5"><Span gradient>Project 1</Span></P>
                <Accordion
                    multiple
                    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                >
                    {#each Object.entries($questions) as [question_key, question_val]}
                        <SingleQuestion
                            question={question_key}
                            title={question_val.title}
                            hideAdjustment={question_key === "q_1"}
                        ></SingleQuestion>
                    {/each}
                </Accordion>
            </div>
        </Card>
    </div>
</div>
