<script lang="ts">
    import { Section, HeroHeader, FeatureDefault, FeatureItem } from "flowbite-svelte-blocks";
    import { ChartPieSolid, LandmarkSolid, BriefcaseSolid, DollarOutline, RocketSolid, CogOutline } from "flowbite-svelte-icons";
  </script>
  
  <Section name="feature">
    <HeroHeader class="max-w-screen-md mb-8 lg:mb-16" h2Class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white" pClass="text-gray-500 sm:text-xl dark:text-gray-400">
      <svelte:fragment slot="h2">Designed for R&D consultants and teams</svelte:fragment>
    </HeroHeader>
    <FeatureDefault class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <FeatureItem>
        <svelte:fragment slot="icon"><ChartPieSolid class="text-purple-600 dark:text-purple-300" /></svelte:fragment>
        <svelte:fragment slot="h3">Draft</svelte:fragment>
        <svelte:fragment slot="paragraph">AI drafts technical narratives for AIF within seconds, based on notes from technical interviews
</svelte:fragment>
      </FeatureItem>
      <FeatureItem>
        <svelte:fragment slot="icon"><LandmarkSolid class="text-purple-600 dark:text-purple-300" /></svelte:fragment>
        <svelte:fragment slot="h3">Research</svelte:fragment>
        <svelte:fragment slot="paragraph">AI assesses technical baseline by automatically searching on the Internet
</svelte:fragment>
      </FeatureItem>
      <FeatureItem>
        <svelte:fragment slot="icon"><BriefcaseSolid class="text-purple-600 dark:text-purple-300" /></svelte:fragment>
        <svelte:fragment slot="h3">Review</svelte:fragment>
        <svelte:fragment slot="paragraph">AI provides comprehensive, tailored feedback on narratives, helping to make it even stronger
</svelte:fragment>
      </FeatureItem>
    </FeatureDefault>
  </Section>
