<script>
  import { onMount } from "svelte";
  import FileUploader from "./FileUploader.svelte";
  let conversations = [
      { id: 1, title: "Conversation 1", messages: [] },
      { id: 2, title: "Conversation 2", messages: [] }
  ];
  let currentConversationId = conversations[0].id;
  let messages = conversations[0].messages;
  let message = "";
  let selectedModel = "";
  let attachment = null;

  const models = ["Model A", "Model B", "Model C"]; // Example models, replace with actual ones

  function sendMessage() {
      if (message.trim() === "" && !attachment) return;
      messages = [...messages, { text: message, sender: "user", attachment }];
      message = "";
      attachment = null;

      // Simulate a response (for demo purposes)
      setTimeout(() => {
          messages = [...messages, { text: "This is a bot response!", sender: "bot" }];
      }, 1000);
  }

  function handleKeyPress(event) {
      if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          sendMessage();
      }
  }

  function handleFileUpload(event) {
      attachment = event.target.files[0];
  }

  function loadConversation(conversationId) {
      const conversation = conversations.find(c => c.id === conversationId);
      if (conversation) {
          currentConversationId = conversationId;
          messages = conversation.messages;
      }
  }
</script>

<style>
  div.flex-1 {
      min-height: 0;
  }
</style>

<div class="flex h-screen">
  <!-- Side Pane for Conversations -->
  <aside class="w-64 bg-gray-200 p-4 border-r">
      <h2 class="text-lg font-semibold mb-4">Conversations</h2>
      <button
        class="w-full mb-4 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
        on:click={() => {
          const newId = conversations.length + 1;
          conversations = [...conversations, { id: newId, title: `New Conversation ${newId}`, messages: [] }];
          loadConversation(newId);
        }}
      >
        New Conversation
      </button>
      <ul class="space-y-2">
          {#each conversations as { id, title }}
              <li
                  class="p-2 bg-white rounded-md cursor-pointer hover:bg-blue-100 transition duration-200"
                  on:click={() => loadConversation(id)}
              >
                  {title}
              </li>
          {/each}
      </ul>
  </aside>

  <!-- Main Chat Area -->
  <div class="flex flex-col flex-1 max-w-3xl mx-auto p-4">
      <div class="mb-4">
          <label for="model-select" class="block text-gray-700 font-medium mb-2">Select Model</label>
          <select id="model-select" bind:value={selectedModel} class="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" disabled>Select a model...</option>
              {#each models as model}
                  <option value={model}>{model}</option>
              {/each}
          </select>
      </div>

      <div class="flex-1 overflow-y-auto bg-gray-100 rounded-md p-4 space-y-2">
          {#each messages as { text, sender, attachment }, index}
              <div
                  class="flex items-start space-x-2 p-3 rounded-md"
                  class:bg-blue-100={sender === "user"}
                  class:bg-green-100={sender === "bot"}
              >
                  <div class="flex-1">
                      <p class="text-gray-800">{text}</p>
                      {#if attachment}
                          <a href={URL.createObjectURL(attachment)} target="_blank" class="text-blue-500 underline mt-1 block">Download Attachment</a>
                      {/if}
                  </div>
              </div>
          {/each}
      </div>

      <div class="mt-4 mb-40">
        <div class="mb-4">
          <FileUploader module="chat" />
        </div>
          <textarea
              bind:value={message}
              on:keydown={handleKeyPress}
              placeholder="Type a message..."
              class="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
              rows="2"
          ></textarea>



      </div>
  </div>
</div>
