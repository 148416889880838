<script>
    import { toast } from "@zerodevx/svelte-toast";
    import { AccordionItem, Button, Spinner, Toggle } from "flowbite-svelte";
    import {
        AdjustmentsHorizontalOutline,
        FileCopyOutline,
        ExclamationCircleOutline,
        CheckCircleOutline,
    } from "flowbite-svelte-icons";
    import { marked } from "marked";
    import { askAssistant } from "../service/http_calls";
    import {
        ajust_response_modal_open,
        current_user,
        fact_checks_modal_open,
        general_prompt,
        paragraph_to_regenerate,
        question_prompt_edit_open,
        questions,
        regenerate_paragraph_modal_open,
        regenerated_paragraph,
        selected_question,
        source_sentence_questions_modal_open,
        sources,
    } from "../service/store";
    import { onMount } from "svelte";

    export let question;
    export let title;
    export let hideAdjustment = false;
    let loading = false;
    async function handleCompile(question) {
        questions.update((prev) => {
            prev[question].response.status = "processing";
            return prev;
        });
        loading = true;
        const depenency_request_ids = $questions[question].dependencies
            .map((dependency) => $questions[dependency].request_id)
            .filter((id) => id !== undefined && id !== "");
        let resp = await askAssistant(
            $general_prompt,
            $questions[question].prompt,
            question,
            "draft",
            depenency_request_ids,
        );
        questions.update((prev) => {
            prev[question].result_id = resp.result_id;
            return prev;
        });
        if (resp.status == "rejected") {
            toast.push(resp.reason);
        } else {
            toast.push("Request submitted");
        }

        loading = false;
    }
    function makeReferencesClickable(content) {
        return content.replace(
            /\[(\d+)\]/g,
            '<a href="#" data-ref="$1">[$1]</a>',
        );
    }

    $: formattedContent = makeReferencesClickable(
        $questions[question]?.response?.content || "",
    );

    let status;
    $: status = $questions[question]?.response?.status;
    let edit_mode = false;
    let edit_content = "";
    let textarea;
    function adjustHeight() {
        if (textarea) {
            textarea.style.height = "auto"; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
        }
    }
    onMount(() => {
        edit_content = $questions[question]?.response?.content || "";
        requestAnimationFrame(adjustHeight); // Use requestAnimationFrame for better timing
    });
</script>

<AccordionItem>
    <span slot="header">{title}</span>

    {#if formattedContent}
        {#if edit_mode}
            <div class="prose max-w-full">
                <textarea
                    bind:this={textarea}
                    bind:value={edit_content}
                    class="w-full"
                    style="height: auto; min-height: 100px; resize: none;"
                ></textarea>
            </div>
        {:else}
            <div
                role="button"
                tabindex="0"
                class="prose max-w-full hover:prose-p:underline hover:prose-p:decoration-purple-200 hover:prose-p:decoration-2 hover:prose-ul:underline hover:prose-ul:decoration-purple-200 hover:prose-ul:decoration-2"
                on:click={(event) => {
                    // toast.push("Fetching sources...");
                    if (
                        event.target.tagName.toLowerCase() === "p" ||
                        event.target.tagName.toLowerCase() === "ul"
                    ) {
                        const paragraphContent = event.target.innerText;
                        console.log(paragraphContent);
                        selected_question.set(question);
                        paragraph_to_regenerate.set(paragraphContent);
                        regenerated_paragraph.set("");
                        regenerate_paragraph_modal_open.set(true);
                    }

                    if (
                        event.target.tagName === "A" &&
                        event.target.dataset.ref
                    ) {
                        event.preventDefault();
                        const refNumber = event.target.dataset.ref;
                        console.log(`Reference [${refNumber}] clicked`);
                        // You can handle the click here, e.g., scroll to a reference, open a modal, etc.
                        source_sentence_questions_modal_open.set(true);
                        sources.set(
                            $questions[question].response.sources[refNumber],
                        );
                    }
                }}
                on:keydown={(event) => {
                    console.log(event);
                }}
            >
                {@html marked(formattedContent)}
            </div>
        {/if}
    {:else}
        <div class="prose max-w-full">
            <p>No content generated yet</p>
        </div>
    {/if}
    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    let cleanedText = $questions[
                        question
                    ]?.response.content.replace(/\[\d+\]/g, "");
                    navigator.clipboard.writeText(cleanedText);
                    toast.push("Copied to clipboard!");
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>
            <Toggle
                bind:checked={edit_mode}
                on:change={(e) => {
                    if (!e.target.checked) {
                        // Going from checked to unchecked
                        const hasUnsavedChanges =
                            edit_content !==
                            $questions[question]?.response?.content;
                        if (hasUnsavedChanges) {
                            window.addEventListener("beforeunload", (event) => {
                                event.preventDefault();
                                toast.push(
                                    "You have unsaved changes. Please first save your changes.",
                                );
                                event.returnValue = "";
                            });
                            const confirm_exit = confirm(
                                "You have unsaved changes. Are you sure you want to exit edit mode?",
                            );
                            if (!confirm_exit) {
                                edit_mode = true; // Keep toggle checked
                                requestAnimationFrame(adjustHeight);
                                e.preventDefault();
                                return;
                            } else {
                                window.removeEventListener(
                                    "beforeunload",
                                    null,
                                );
                            }
                        }
                    }else{
                        edit_content =
                        $questions[question]?.response?.content || "";
                    }

                    requestAnimationFrame(adjustHeight);
                }}
                size="small"
                class="mr-5"
            >
                Edit Mode
            </Toggle>

            {#if edit_mode}
                <Button
                    size="xs"
                    color="alternative"
                    on:click={() => {
                        $questions[question].response.content = edit_content;
                        edit_mode = false;
                        toast.push("Changes saved");
                    }}
                >
                    Save Changes
                </Button>
            {/if}
        </div>

        <div class="flex justify-end">
            {#if !hideAdjustment && $current_user?.user_type === "admin"}
                <button
                    on:click={() => {
                        ajust_response_modal_open.set(true);
                    }}
                    style="background: none; border: none; cursor: pointer;"
                    class="mr-5"
                >
                    <AdjustmentsHorizontalOutline
                    ></AdjustmentsHorizontalOutline>
                </button>
            {/if}

            <!-- {#if status === "done" || typeof status === "undefined"} -->

            {#if $questions[question]?.response?.fact_checks}
                <Button
                    size="xs"
                    color="alternative"
                    class="mr-5"
                    on:click={() => {
                        selected_question.set(question);
                        fact_checks_modal_open.set(true);
                    }}
                >
                    {#if $questions[question]?.response?.fact_checks
                        ?.toLowerCase()
                        .trim()
                        .includes("no hallucination detected")}
                        <CheckCircleOutline
                            class="w-4 h-4 me-2 text-green-700"
                        />
                    {:else}
                        <ExclamationCircleOutline
                            class="w-4 h-4 me-2 text-red-700"
                        />
                    {/if}
                    Show Fact Checks
                </Button>
            {/if}

            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    source_sentence_questions_modal_open.set(true);
                    sources.set(
                        $questions[question].response.chunks.join(
                            "\n\n---\n\n",
                        ),
                    );
                }}>Show All Sources</Button
            >
            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    question_prompt_edit_open.set(true);
                    selected_question.set(question);
                }}>Add Instructions</Button
            >
            {#if loading}
                <Spinner></Spinner>
            {:else}
                <Button
                    size="xs"
                    color="purple"
                    on:click={() => {
                        handleCompile(question);
                    }}>Regenerate</Button
                >
            {/if}
            {#if status !== "done" && typeof status !== "undefined"}
                <div class="ml-5 flex flex-row mt-1">
                    <small>Status: <b>{status}</b></small>
                    <Spinner size={5} class="ml-3"></Spinner>
                </div>
            {/if}
        </div>
    </div>
</AccordionItem>
