<script>
    import { Alert } from "flowbite-svelte";
    setTimeout(function () {
        window.location.href = "/profile";
    }, 5000);
</script>

<div class="max-w-full justify-center flex ">
    <div class="mt-5 ">
        <Alert color="purple">
            <span class="font-medium">Successfuly Updated Your Subscription!</span>
            Thanks for your subscription, now you will be redirected to the home
            page....
        </Alert>
    </div>
</div>
