<div>
	<div style="clear:both">
		<p class="Header">&#xa0;</p>
	</div>
	<p
		style="margin-bottom:10pt; text-align:center; line-height:115%; font-size:10pt"
	>
		<strong><u>Software as a Service Terms and Conditions</u></strong>
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		<strong>&#xa0;</strong>
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		These terms and conditions, order form, and any other terms and policies
		referred to herein, form the agreement between SMARTCLAIM TECHNOLOGY
		LTD, a company incorporated in England whose registered office is at 52a
		Westerham Road, Sevenoaks, England, TN13 2PZ (“<strong>Supplier”</strong
		>) and the user (“<strong>Customer</strong>”), collectively referred to
		as the Parties or each a Party, (the “<strong>Agreement</strong>”).
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		The Supplier owns and holds the relevant rights to the Software and has
		agreed to grant a licence to the Customer to use Supplier's Services in
		its business operations.
	</p>
	<p
		style="margin-top:12pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		By accessing and/or using the Services the Customer warrants to the
		Supplier that it has reviewed this Agreement, including the End User
		Licence and Privacy Policy and agrees to the Services in accordance with
		this Agreement.
	</p>
	<p
		style="margin-top:12pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		<strong>Agreed Terms </strong>
	</p>
	<ol class="awlist1" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Definitions and Interpretation</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Authorised Users</strong>: those employees,
					agents and independent contractors of the Customer who are
					authorised by the Customer to use the Services, as further
					described in clause 3.2.3.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Business Day</strong>: a day other than a
					Saturday, Sunday or public holiday in England when banks in
					London are open for business.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Confidential Information</strong>:
					information that is proprietary or confidential and is
					either clearly labelled as such or identified as
					Confidential Information in clause 11.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Customer Data</strong>: the data inputted by
					the Customer, Authorised Users, or the Supplier on the
					Customer's behalf for the purpose of using the Services or
					facilitating the Customer's use of the Services.&#xa0;
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Data Protection Laws: </strong>all relevant
					data protection laws, regulations and rules which may apply
					to the Parties, including but not limited to the UK Data
					Protection Act 2018, the General Data Protection Regulation
					2016/679, and the Privacy and Electronic Communications (EC
					Directive) Regulations 2003. “<strong>Personal Data</strong
					>” and “<strong>Personal Sensitive Data</strong>” shall mean
					as defined within the Data Protection Laws.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Effective Date</strong>: means the date
					agreed by the parties for the Services to start and as shown
					on the Order Form.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Initial Subscription Term</strong>: the
					initial term (if any) of this Agreement as set out in the
					Order Form.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Normal Business Hours</strong>: 9.00 am to
					6.00 pm local UK time, each Business Day.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Order Form: </strong>means a form setting out
					the details of the Services requested by the Customer and
					certain other terms of the Agreement.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Renewal Period</strong>: the period described
					in clause 14.1.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Services</strong>: the online software
					subscription services provided by the Supplier to the
					Customer via the internet under this Agreement.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Software</strong>: the online software
					platform provided by the Supplier as part of the Services,
					to include the Supplier’s website at
					<span style="background-color:#ffff00">smartclaim.uk</span> and
					any other technology platform through which the Services are
					provided.
				</li>
				<li style="margin-left:36pt; text-indent:-40.24pt">
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Special Terms</strong>: means any terms that
					may be set out or referred to in an Order Form and/or that
					may be attached to this Agreement, and which may modify or
					supplement this Agreement.
				</li>
			</ol>
		</li>
	</ol>
	<p
		style="margin-left:72pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="14" class="awlist3" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Subscription Fees</strong>: the subscription fees
			payable by the Customer to the Supplier for the User Subscriptions,
			as set out in Order Form.
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Subscription Term</strong>: has the meaning given in
			clause 14.3 (being the Initial Subscription Term together with any
			subsequent Renewal Periods) and as set out in the Order Form.
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Usage Volume: the total number of new claims or actions
			generated by the Customer during the Subscription Term, as agreed in
			the Order Form.
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>User Subscriptions</strong>: the user subscriptions
			purchased by the Customer pursuant to clause 10.1 which entitle
			Authorised Users to access and use the Services in accordance with
			this Agreement.
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Virus</strong>: any thing or device (including any
			software, code, file or programme) which may: prevent, impair or
			otherwise adversely affect the operation of any computer software,
			hardware or network, any telecommunications service, equipment or
			network or any other service or device; prevent, impair or otherwise
			adversely affect access to or the operation of any programme or
			data, including the reliability of any programme or data (whether by
			rearranging, altering or erasing the programme or data in whole or
			part or otherwise); or adversely affect the user experience,
			including worms, trojan horses, viruses and other similar things or
			devices.
		</li>
	</ol>
	<ol start="2" class="awlist4" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Interpretation </strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li style="margin-left:36pt; text-indent:-34.68pt">
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>In the event of any inconsistency or conflict among
					the provisions of this Agreement, any Order Form, and/or any
					Special Terms, the following order of precedence shall
					apply: (i) the Special Terms; (ii) the Order Form;&#xa0; and
					(iii) this Agreement.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>User subscriptions </strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Subject to the Customer purchasing the User
					Subscriptions in accordance with clause 4.3 and clause 10.1,
					the&#xa0; restrictions set out in this clause 3, and the
					other terms and conditions of this Agreement, the Supplier
					hereby grants to the Customer a non-exclusive,
					non-transferable right and licence to permit the Authorised
					Users to use the Services during the Subscription Term
					solely for the Customer's internal business operations.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>In relation to the Authorised Users, the Customer
					undertakes that:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the maximum number of Authorised Users that
							it authorises to access and use the Services shall
							not exceed the number of User Subscriptions it has
							purchased from time to time;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>it will not allow or suffer any User
							Subscription to be used by more than one individual
							Authorised User unless it has been reassigned in its
							entirety to another individual Authorised User, in
							which case the prior Authorised User shall no longer
							have any right to access or use the Services;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>it shall maintain a written, up to date list
							of current Authorised Users and provide such list to
							the Supplier within a reasonable timeframe of the
							Supplier's written request at any time or times.
						</li>
					</ol>
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall not:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>except as may be allowed by any applicable
							law which is incapable of exclusion by agreement
							between the Parties and except to the extent
							expressly permitted under this Agreement:
							<ol
								class="awlist6"
								style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
							>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>attempt to copy, modify, duplicate,
									create derivative works from, frame, mirror,
									republish, download, display, transmit, or
									distribute all or any portion of the
									Software in any form or media or by any
									means; or
								</li>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>attempt to de-compile, reverse
									compile, disassemble, reverse engineer or
									otherwise reduce to human-perceivable form
									all or any part of the Software or the
									Services; or
								</li>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>access all or any part of the
									Services in in order to build a product or
									service which competes with the Services;
									or&#xa0;
								</li>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>subject to clause 3.1, licence,
									sublicense, sell, rent, lease, transfer,
									assign, distribute, display, disclose, or
									otherwise commercially exploit, or
								</li>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>otherwise make the Services available
									to any third party except the Authorised
									Users, or
								</li>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>attempt to obtain, or assist third
									parties in obtaining, access to the
									Services, other than as provided under this
									clause 3; or
								</li>
								<li
									style="margin-left:36pt; margin-bottom:10pt; text-indent:-51.36pt"
								>
									<span
										style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
										>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
									</span>introduce or permit the introduction
									of any Virus into the Services or the
									Supplier's network and information
									systems.&#xa0;
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall use all reasonable endeavours to
					prevent any unauthorised access to, or use of, the Services
					in the event of any such unauthorised access or use,
					promptly notify the Supplier.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The rights provided under this clause 3 are granted
					to the Customer only, and shall not be considered granted to
					any subsidiary or holding company of the Customer.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Additional user subscriptions</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Subject to clause 4.2 and clause 4.3, the Customer
					may, from time to time during any Subscription Term,
					purchase additional User Subscriptions in excess of the
					number set out in the Order Form and the Supplier shall
					grant access to the Services to such additional Authorised
					Users in accordance with the provisions of this Agreement.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>If the Customer wishes to purchase additional User
					Subscriptions, the Customer shall notify the Supplier in
					writing. The Supplier shall evaluate such request for
					additional User Subscriptions and respond to the Customer
					with approval or rejection of the request, such approval not
					to be unreasonably withheld.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>If the Supplier approves the Customer's request to
					purchase additional User Subscriptions, the Customer shall,
					within 30 days of the date of the Supplier's invoice, pay to
					the Supplier the relevant fees for such additional User
					Subscriptions as set out in the Order Form and, if such
					additional User Subscriptions are purchased by the Customer
					part way through the Initial Subscription Term or any
					Renewal Period (as applicable), such fees shall be pro-rated
					from the date of activation by the Supplier for the
					remainder of the Initial Subscription Term or then current
					Renewal Period (as applicable).
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Services</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall, during the Subscription Term,
					provide the Services to the Customer on and subject to the
					terms of this Agreement.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span> Usage Volume is monitored based on the number of Generative
					AI calls made. If the Customer exceeds this limit, the Supplier
					will engage in discussions to renegotiate pricing for any additional
					usage beyond the agreed limit.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Services are provided over the internet, and as
					such, the quality and availability of such may be affected
					by factors beyond the Supplier’s reasonable control,
					including but not limited to network interruptions,
					third-party service failures, or other unforeseen technical
					issues. The Supplier shall of course use commercially
					reasonable endeavours to make the Services available 24
					hours a day, seven days a week, except for:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>any planned maintenance; and
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>unscheduled maintenance performed outside
							Normal Business Hours, provided that the Supplier
							has used reasonable endeavours to give the Customer
							reasonable notice in advance.
						</li>
					</ol>
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Data Protection</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Both Parties shall comply with its obligations under
					the Data Protection Laws and the Supplier’s Privacy Policy.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer acknowledges and agrees that any
					Personal Data provided by the Customer to the Supplier in
					connection with the provision of the Services will be
					processed by OpenAI under its enterprise plan in accordance
					with applicable Data Protection Laws and by using the
					Services the Customer consents to OpenAI acting as a
					sub-processor of the Supplier.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall only process Personal Data as
					necessary to provide the Services in accordance with this
					Agreement and the Customer’s instructions.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall implement appropriate technical
					and organisational measures to ensure a level of security
					appropriate to the risk, to protect personal data from
					unauthorised access, accidental loss, destruction, or
					damage.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Supplier’s obligations</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall perform the Services with
					reasonable skill and care.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier uses Generative AI as part of its
					Services. While the Supplier shall comply with clause 7.1,
					it does not provide any warranties as to the content that
					Generative AI produces. The Customer acknowledges and agrees
					that they are solely responsible for reviewing, verifying,
					and assessing the output before relying on or using it for
					any purpose.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer acknowledges and agrees that the any
					results provided by the Supplier to the Customer are for
					informational purposes only and should not be considered
					legal advice. The Customer should seek independent legal
					advice for any legal matters.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall not be liable for any
					non-conformance which is caused by use of the Services
					contrary to the Supplier's instructions, or modification or
					alteration of the Services by any party other than the
					Supplier or the Supplier's duly authorised contractors or
					agents. If the Services do not conform with the terms of
					clause 7.1, Supplier will, at its expense, use reasonable
					commercial endeavours to correct any such non-conformance
					promptly. Such correction constitutes the Customer's sole
					and exclusive remedy for any breach of the undertaking set
					out in clause 7.1.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier is not responsible for any delays,
					delivery failures, or any other loss or damage resulting
					from the transfer of data over communications networks and
					facilities, including the internet, and the Customer
					acknowledges that the Services&#xa0; may be subject to
					limitations, delays and other problems inherent in the use
					of such communications facilities.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier warrants that it has and will maintain
					all necessary licences, consents, and permissions necessary
					for the performance of its obligations under this Agreement.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Customer’s obligations</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>provide the Supplier with all necessary
							co-operation and access to such information in
							relation to this Agreement in order to provide the
							Services;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>without affecting its other obligations under
							this Agreement, comply with all applicable laws and
							regulations with respect to its activities under
							this Agreement;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>carry out all other Customer responsibilities
							set out in this Agreement in a timely and efficient
							manner. In the event of any delays in the Customer's
							provision of such assistance as agreed by the
							Parties, the Supplier may adjust any agreed
							timetable or delivery schedule as reasonably
							necessary;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>ensure that the Authorised Users use the
							Services in accordance with the terms and conditions
							of this Agreement and shall be responsible for any
							Authorised User's breach of this Agreement;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>obtain and shall maintain all necessary
							licences, consents, and permissions necessary for
							the Supplier, its contractors and agents to perform
							their obligations under this Agreement, including
							without limitation the Services;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>ensure that its network and systems comply
							with the relevant specifications provided by the
							Supplier from time to time; and
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-43.02pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>be, to the extent permitted by law and except
							as otherwise expressly provided in this Agreement,
							solely responsible for procuring, maintaining and
							securing its network connections and
							telecommunications links from its systems to the
							Supplier's data centres, and all problems,
							conditions, delays, delivery failures and all other
							loss or damage arising from or relating to the
							Customer's network connections or telecommunications
							links or caused by the internet.
						</li>
					</ol>
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall own all right, title and interest
					in and to all of the Customer Data that is not personal data
					and shall have sole responsibility for the legality,
					reliability, integrity, accuracy and quality of all such
					Customer Data.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer acknowledges and agrees that the
					Supplier’s Software generates output data based on the
					Customer’s inputted data. In the event of an HMRC inquiry
					into a research and development tax relief claim prepared
					and submitted by the Customer using such output data, the
					Supplier shall not be liable for any consequences arising
					from such investigation, in accordance with clause 13.3.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-26.34pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Charges and payment</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-34.68pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall pay the Subscription Fees to the
					Supplier for the User Subscriptions in accordance with this
					clause 9.
				</li>
				<li style="margin-left:36pt; text-indent:-34.68pt">
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Subscription Fees shall be exclusive of any Value
					Added Tax which may be payable in connection with the supply
					of the Services by the Customer. Unless otherwise specified
					in the invoice, all amounts payable by the Customer excludes
					amounts in respect of value added tax (VAT).
				</li>
			</ol>
		</li>
	</ol>
	<p
		style="margin-left:72pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="3" class="awlist7" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-34.68pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>The Supplier shall issue invoices for the Subscription Fees
			as set out in the Order Form.&#xa0;
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-34.68pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Payments are due as specified in the Order Form. If the
			Customer fails to pay any amount due under this Agreement, the
			Supplier shall reserve the right to revoke any licences granted
			under this Agreement. The Supplier shall be entitled to increase the
			Subscription Fees and the fees payable in respect of the additional
			User Subscriptions purchased pursuant to clause 4.3 at the start of
			each Renewal Period upon 90 days' prior notice to the Customer.
		</li>
	</ol>
	<ol start="10" class="awlist8" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-31.9pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Intellectual Proprietary rights</strong>
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer acknowledges and agrees that the
					Supplier and/or its licensors owns all intellectual property
					rights in the Software. The Customer is granted a
					non-exclusive, non-transferable licence to use the Software
					solely in accordance with the terms of this Agreement.
					Except as expressly stated herein, this Agreement does not
					grant the Customer any rights to, under or in, any patents,
					copyright, database right, trade secrets, trade names, trade
					marks (whether registered or unregistered), or any other
					rights or licences in respect of the Software and/ or
					Services.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier confirms that it has all the rights in
					relation to the Services that are necessary to grant all the
					rights it purports to grant under, and in accordance with,
					the terms of this Agreement.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall retain ownership of all
					intellectual property rights in the data or information
					inputted into the Supplier’s Software as well as the output
					generated from such input.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall not be liable for any third party
					claims or disputes relating to the Customer's input or
					output data in the Software. The Customer assumes full
					responsibility for ensuring that their use of the input and
					output data does not infringe upon third party intellectual
					property rights.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-31.9pt; text-align:justify; line-height:115%; font-size:10pt; font-weight:bold"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Confidential Information
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Each Party undertakes that it shall not at any time
					disclose to any person any confidential information
					concerning the business, affairs, customers, clients or
					suppliers of the other Party or of any member of the group
					to which the other Party belongs, except as permitted by
					clause 11.2. For the purposes of this clause, group means,
					in relation to a Party, that Party, any subsidiary or
					holding company from time to time of that Party, and any
					subsidiary from time to time of a holding company of that
					Party.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Each Party may disclose the other Party's
					confidential information:
				</li>
			</ol>
		</li>
	</ol>
	<ol class="awlist9" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:144pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:9.11pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>to its employees, officers, representatives, subcontractors
			or advisers who need to know such information for the purposes of
			carrying out the Party's obligations under this Agreement. Each
			Party shall ensure that its employees, officers, representatives,
			subcontractors or advisers to whom it discloses the other Party's
			confidential information comply with this clause 11.2;
		</li>
		<li
			style="margin-left:144pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:9.11pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>as may be required by law, a court of competent jurisdiction
			or any governmental or regulatory authority;
		</li>
		<li
			style="margin-left:144pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:9.67pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>If the information was already known or available to the
			receiving Party otherwise than through a breach of any
			confidentiality obligation owed to the disclosing Party;
		</li>
		<li
			style="margin-left:144pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:9.11pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>If the information is or becomes in the public domain other
			than through any breach of this Agreement; and
		</li>
		<li
			style="margin-left:144pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:9.11pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>If the information is disclosed to the recipient without any
			obligation of confidence to the disclosing party by a third party
			who is not itself under or in breach of any obligation of
			confidentiality.
			<ol
				start="3"
				class="awlist10"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>No Party shall use any other Party's confidential
					information for any purpose other than to perform its
					obligations under this Agreement.
				</li>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier warrants that:
					<ol
						class="awlist11"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Customer’s confidential information,
							including but not limited to customer-specific data
							or materials, shall not be disclosed to any third
							party.
						</li>
						<li
							style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>it shall not use such information for the
							purposes of training AI models.
						</li>
					</ol>
				</li>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Confidential information processed by the Supplier’s
					Open AI Enterprise plan is kept secure and not used for AI
					model training.
				</li>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier shall exercise reasonable care to
					prevent cyberattacks through the use of encryption, security
					protocols, and other safeguards designed to protect the
					Customer's confidential information. However, in the event
					of a cyberattack or security breach, the Supplier’s
					liability shall be limited to pursuant to clause 13.
				</li>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Nothing in this clause 11 shall override the Parties'
					obligations under the Data Protection Laws.
				</li>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Subject to clauses 11.3, no Party shall make, or
					permit any person to make, any public announcement
					concerning this Agreement without the prior written consent
					of the other Party (such consent not to be unreasonably
					withheld or delayed), except as required by law, any
					governmental or regulatory authority (including, without
					limitation, any relevant securities exchange), any court or
					other authority of competent jurisdiction.
				</li>
				<li
					style="margin-top:12pt; margin-left:-72pt; margin-bottom:10pt; text-indent:-40.24pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer may make a public reference to the fact
					that itr is using the Services.The Supplier may publicly
					reference the Customer’s use of the Supplier’s Services
					subject to the prior written approval of the Customer and
					pursuant to any confidentiality obligations set out in this
					Agreement. Any reference made by one Party shall not bring
					the other Party into disrepute or damage its reputation.
				</li>
			</ol>
		</li>
	</ol>
	<ol start="12" class="awlist12" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-31.9pt; text-align:justify; line-height:115%; font-size:10pt; font-weight:bold"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Indemnity
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Customer shall indemnify and hold the Supplier
					harmless against any claims, actions, losses, or costs
					(including court fees and reasonable legal expenses) arising
					from the Customer's use of the Services and otherwise any
					breach of the terms of this Agreement, provided that:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Customer is promptly notified of the
							claim;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Supplier provides reasonable cooperation
							at the Customer’s expense; and;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Customer has sole authority to defend or
							settle the claim.
						</li>
					</ol>
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Subject to clause 13, the Supplier shall indemnify
					the Customer against any claims that the use of the Software
					infringes any UK intellectual property rights, provided
					that:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Supplier is promptly notified of the
							claim;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Customer does not admit liability or
							settle the claim and provides reasonable cooperation
							at the Supplier’s expense; and
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>the Supplier has sole authority to defend or
							settle the claim.
						</li>
					</ol>
				</li>
			</ol>
		</li>
		<li
			style="margin-left:36pt; margin-bottom:10pt; text-indent:-31.9pt; text-align:justify; line-height:115%; font-size:10pt; font-weight:bold"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Limitation of liability
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Except as expressly stated in this Agreement:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>The Customer assumes full responsibility for
							results obtained from using the Services, and for
							any conclusions drawn from such use. The Supplier is
							not liable for damage caused by errors or omissions
							in Customer Data, or actions taken at the Customer's
							direction.
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>All implied warranties, conditions, and terms
							are excluded to the fullest extent permitted by law.
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>The Services are provided on an "as is”
							basis.
						</li>
					</ol>
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier’s liability is not excluded for death or
					personal injury caused by the Supplier’s negligence or fraud
					or fraudulent misrepresentation.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier is not liable for any loss of profits,
					business, wasted expenditure, goodwill, data, or any
					special, indirect, or consequential loss.
				</li>
				<li
					style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Supplier’s total liability shall be limited to
					the total Fees paid by the Customer in the 12 months
					preceding the claim.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Liability under this clause includes all forms of
					liability, including contract, tort, negligence,
					misrepresentation, and restitution.
				</li>
			</ol>
		</li>
		<li
			style="margin-top:12pt; margin-left:36pt; margin-bottom:10pt; text-indent:-31.9pt; text-align:justify; line-height:115%; font-size:10pt; font-weight:bold"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Term and Termination
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>This Agreement shall start on the Effective Date and
					shall continue for the Initial Subscription Term as set out
					in the Order Form and, thereafter, this Agreement may&#xa0;
					be renewed for&#xa0; successive periods (each a
					<strong>Renewal Period</strong>) upon mutual agreement
					between the Parties.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Either Party can terminate this Agreement in
					accordance with the Order Form.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Notwithstanding clause 14.2, if any Party breaches
					the terms of this Agreement, the other Party can terminate
					the Agreement immediately. If either Party is dissolved,
					ceases conduct of all of its business, is unable to pay its
					debts, or becomes insolvent, the Agreement will be
					terminated.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The Initial Subscription Term together with any
					subsequent Renewal Periods shall constitute the
					<strong>Subscription Term</strong>.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>On termination of this Agreement for any reason:
					<ol
						class="awlist5"
						style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
					>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>all licences granted under this Agreement
							shall immediately terminate and the Customer shall
							immediately cease all use of the Services;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>each Party shall return and make no further
							use of any equipment, property, and other items (and
							all copies of them) belonging to the other Party;
						</li>
						<li
							style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
						>
							<span
								style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
								>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
							</span>any rights, remedies, obligations or
							liabilities of the parties that have accrued up to
							the date of termination, including the right to
							claim damages in respect of any breach of the
							agreement which existed at or before the date of
							termination shall not be affected or prejudiced.
						</li>
					</ol>
				</li>
			</ol>
		</li>
		<li
			style="margin-left:36pt; margin-bottom:10pt; text-indent:-31.9pt; text-align:justify; line-height:115%; font-size:10pt; font-weight:bold"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>General
			<ol
				class="awlist2"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-40.24pt; font-weight:normal"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span><strong>Force majeure </strong>
				</li>
			</ol>
		</li>
	</ol>
	<p
		style="margin-left:108pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Neither Party shall be in breach of this Agreement or otherwise liable
		for any failure or delay in the performance of its obligations if such
		delay or failure results from events, circumstances or causes beyond its
		reasonable control. The time for performance of such obligations shall
		be extended accordingly. If the period of delay or non-performance
		continues for two weeks, the Party not affected may terminate this
		Agreement by giving 2 days written notice to the affected Party.
	</p>
	<ol start="2" class="awlist13" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Conflict </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>If there is an inconsistency between any of the
					provisions in the main body of this Agreement and the Order
					Form, the provisions in the main body of this Agreement
					shall prevail.
				</li>
			</ol>
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Variation </strong>
		</li>
	</ol>
	<p
		style="margin-left:108pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		No variation of this Agreement shall be effective unless it is in
		writing and signed by the Parties (or their authorised representatives).
	</p>
	<ol start="4" class="awlist15" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Waiver </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li style="margin-left:36pt; text-indent:-48.58pt">
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>A waiver of any right or remedy is only effective if
					given in writing and shall not be deemed a waiver of any
					subsequent breach or default. A delay or failure to
					exercise, or the single or partial exercise of, any right or
					remedy shall not:
				</li>
			</ol>
		</li>
	</ol>
	<p
		style="margin-top:12pt; margin-left:94.45pt; margin-bottom:12pt; text-align:justify; line-height:115%; padding-left:26pt; font-size:10pt"
	>
		a) <span style="width:11.88pt; display:inline-block">&#xa0;</span>waive
		that or any other right or remedy; or
	</p>
	<p
		style="margin-top:12pt; margin-left:115.7pt; margin-bottom:12pt; text-indent:-21.25pt; text-align:justify; line-height:115%; padding-left:26pt; font-size:10pt"
	>
		b) <span style="width:9.58pt; text-indent:0pt; display:inline-block"
			>&#xa0;</span
		>prevent or restrict the further exercise of that or any other right or
		remedy.
	</p>
	<ol start="5" class="awlist16" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Rights and remedies </strong>
		</li>
	</ol>
	<p
		style="margin-left:108pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Except as expressly provided in this Agreement, the rights and remedies
		provided under this Agreement are in addition to, and not exclusive of,
		any rights or remedies provided by law.
	</p>
	<ol start="6" class="awlist17" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Entire agreement </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>This Agreement constitutes the entire agreement
					between the Parties and supersedes and extinguishes all
					previous and contemporaneous agreements, promises,
					assurances and understandings between them, whether written
					or oral, relating to its subject matter.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Each Party agrees that it shall have no claim for
					innocent or negligent misrepresentation based on any
					statement in this Agreement.
				</li>
			</ol>
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Assignment </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li style="margin-left:36pt; text-indent:-48.58pt">
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Save as provided in clauses 15.7.2 and 15.7.3,
					neither Party may subcontract, assign, transfer, novate,
					grant any trust over, or otherwise deal with any of its
					rights or obligations under this Agreement without the prior
					written consent of the other Party, such request not to be
					unreasonably withheld or delayed.
				</li>
			</ol>
		</li>
	</ol>
	<p
		style="margin-left:108pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="2" class="awlist18" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:108pt; text-indent:-48.58pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>Notwithstanding clause 15.7.1, either Party may assign,
			transfer, novate, grant any trust over, or otherwise deal with its
			rights and obligations under this Agreement, in part or in full,
			without the prior written consent of the other Party to an acquirer
			of assets or a successor by merger.
		</li>
	</ol>
	<p
		style="margin-left:120.45pt; text-indent:-18pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="3" class="awlist19" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:108pt; text-indent:-48.58pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>The Supplier shall be entitled to subcontract its
			non-material obligations under this Agreement to any third party or
			engage third party agents or subcontractors without obtaining the
			Customer’s prior consent.
		</li>
	</ol>
	<p
		style="margin-left:108pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="8" class="awlist20" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>No partnership or agency </strong>
		</li>
	</ol>
	<p
		style="margin-left:108pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Nothing in this Agreement is intended to or shall operate to create a
		partnership between the Parties, or authorise either Party to act as
		agent for the other, and neither Party shall have the authority to act
		in the name or on behalf of or otherwise to bind the other in any way
		(including, but not limited to, the making of any representation or
		warranty, the assumption of any obligation or liability and the exercise
		of any right or power). <br />&#xa0;
	</p>
	<ol start="9" class="awlist21" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-40.24pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Third party rights </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-48.58pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>This Agreement does not give rise to any rights under
					the Contracts (Rights of Third Parties) Act 1999 to enforce
					any term of this Agreement.
				</li>
			</ol>
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-45.8pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Counterparts </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-54.14pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>This Agreement may be executed in any number of
					counterparts, each of which shall constitute a duplicate
					original, but all the counterparts shall together constitute
					the one Agreement.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-54.14pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The executed signature page of a counterpart of this
					Agreement] by email (in PDF, JPEG or other agreed format)
					shall take effect as the transmission of an executed
					"wet-ink" counterpart of this Agreement.
				</li>
			</ol>
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-45.8pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Notices </strong>
			<ol
				class="awlist14"
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt"
			>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-54.14pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Any notice of termination of this Agreement by the
					Customer must be delivered to the Supplier by email on
					<a
						href="/cdn-cgi/l/email-protection#0b7863626762654b78666a797f68676a6266257e60"
						style="text-decoration:none"
						><u
							><span style="color:#1155cc"
								><span
									class="__cf_email__"
									data-cfemail="bfccd7d6d3d6d1ffccd2decdcbdcd3ded6d291cad4"
									>[email&#160;protected]</span
								></span
							></u
						></a
					> or any alternative email address designated by the Supplier
					for this purpose from time to time and must be given by an authorised
					signatory of the Customer.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-54.14pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>Without prejudice to clause 15.11.1, any notice or
					other communication given to a Party under or in connection
					with this Agreement shall be in writing, addressed to that
					Party at its registered office or such other address as that
					Party may have specified to the other Party in writing in
					accordance with this clause, and shall be delivered
					personally, or sent by pre-paid first class post or other
					next working day delivery service, commercial courier, or
					email.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-54.14pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>A notice or other communication shall be deemed to
					have been received: if delivered personally, when left at
					the address referred to in clause 15.11.1; if sent by
					pre-paid first class post or other next working day delivery
					service, at 9.00 am on the second Business Day after
					posting; if delivered by commercial courier, on the date and
					at the time that the courier's delivery receipt is signed;
					or, if sent by or email, one Business Day after
					transmission.
				</li>
				<li
					style="margin-left:36pt; margin-bottom:10pt; text-indent:-54.14pt"
				>
					<span
						style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
						>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
					</span>The provisions of this clause shall not apply to the
					service of any proceedings or other documents in any legal
					action.
				</li>
			</ol>
		</li>
		<li
			style="margin-left:72pt; margin-bottom:10pt; text-indent:-45.8pt; text-align:justify; line-height:115%; font-size:10pt"
		>
			<span
				style="width:18pt; font:7pt 'Times New Roman'; display:inline-block"
				>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><strong>Governing Law</strong>
		</li>
	</ol>
	<p
		style="margin-right:5pt; margin-left:108pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		This Agreement is to be governed by and construed in accordance with
		English law.
	</p>
	<p
		style="margin-right:5pt; margin-left:28.3pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		14.13<span style="width:18.68pt; display:inline-block">&#xa0;</span
		><strong>Dispute Resolution</strong>
	</p>
	<p
		style="margin-right:5pt; margin-left:106.25pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		In the event of any dispute or claim, the Parties shall first attempt to
		resolve the matter through good faith negotiations. Should those
		negotiations fail, the Parties agree that the courts of England and
		Wales will have exclusive jurisdiction over any disputes.
	</p>
	<p>
		<br style="page-break-before:always; clear:both" />
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		<strong><u>SmartClaim End User Licence Agreement</u></strong>
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		<strong>&#xa0;</strong>
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Last updated: 17-09-2024
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		These terms and conditions (“<strong>Terms</strong>”) govern your use of
		the website (“<strong>Website</strong>”) provided by&#xa0; Smartclaim
		Technologies Ltd (‘<strong>SmartClaim</strong>”) and sets out a list of
		acceptable and unacceptable conduct of our Website.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Acceptance of Terms
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt; background-color:#ffffff"
	>
		By accessing or using the Website you agree to be bound by these Terms.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt; background-color:#ffffff"
	>
		SmartClaim may change the Terms at any time by posting a revised version
		on the Website. Any change shall come into effect and be deemed accepted
		if you continue using the Website after the revised version is posted.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Please review these Terms carefully before using the Website, and
		continue to check here periodically for any updates.
	</p>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:13pt; font-size:10pt; font-weight:bold"
		>
			User Accounts
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Before you start using the Website, you must create and/or activate your
		account.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		You will be asked to provide your first and last name, and email
		address.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		If you are provided with, or create, a password or any other security
		information during registration, you must treat it as confidential. Your
		account is personal to you, and you should not allow others to access
		it. To protect your account, we recommend logging out after using the
		Website, especially if accessing it from a shared device.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		It is your responsibility to immediately inform SmartClaim team at <a
			href="/cdn-cgi/l/email-protection"
			class="__cf_email__"
			data-cfemail="e5968d8c898c8ba596888497918689848c88cb908e"
			>[email&#160;protected]</a
		>, if you think your personal information, including but not limited to
		account details, access credentials or personal data, have been
		violated, unduly disclosed or stolen.
	</p>
	<ol start="3" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Licence
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		SmartClaim grants you a non-exclusive, non-transferable, royalty-free
		and revocable licence to use the Website&#xa0; in accordance with these
		Terms.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		All trademark rights, copyright, database rights, and any other
		intellectual property rights of any nature vesting in the Website
		together with the underlying software code are exclusively owned by
		SmartClaim and / or its group of companies (as prescribed between them).
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="4" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Conditions of Use
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		You will comply with the following conditions at all times:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-left:67.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:4.66pt; font-size:10pt; background-color:#ffffff"
		>
			You will comply with all applicable laws and regulations and will
			not use the website for any improper or fraudulent purposes;
		</li>
		<li
			style="margin-left:67.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:4.66pt; font-size:10pt; background-color:#ffffff"
		>
			You acknowledge that your use of the Website may be subject to
			third-party terms;
		</li>
		<li
			style="margin-left:66.78pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:5.22pt; font-size:10pt; background-color:#ffffff"
		>
			You will not (and not allow third parties on your behalf to):
			<ol
				style="margin-right:0pt; margin-left:0pt; padding-left:0pt; list-style-type:lower-roman"
			>
				<li
					style="margin-left:23pt; margin-bottom:10pt; padding-left:13pt"
				>
					attempt to copy, rent, sub-licence, loan, merge, reproduce,
					alter, modify, reverse engineer, disassemble, decompile,
					transfer, exchange, translate, hack, distribute, harm or
					misuse the Website; or
				</li>
				<li
					style="margin-left:23pt; margin-bottom:10pt; padding-left:13pt"
				>
					create derivative works of the Website of any kind
					whatsoever; or
				</li>
				<li
					style="margin-left:23pt; margin-bottom:10pt; padding-left:13pt"
				>
					attempt to gain unauthorised access to the Website,
					networks, systems or data, or use the Website&#xa0; to
					compromise the security of any system or network; or
				</li>
				<li
					style="margin-left:23pt; margin-bottom:10pt; padding-left:13pt"
				>
					transmit or introduce any viruses, malware, worms, or other
					harmful or destructive code through the Website.
				</li>
			</ol>
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		You acknowledge and agree that:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-left:67.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:4.66pt; font-size:10pt; background-color:#ffffff"
		>
			The Website is provided over the internet and therefore the quality
			and availability of the Website may be affected by factors outside
			of SmartClaim’s reasonable control.
		</li>
		<li
			style="margin-left:67.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:4.66pt; font-size:10pt; background-color:#ffffff"
		>
			SmartClaim, its group of companies and subcontractors do not accept
			any responsibility whatsoever for unavailability of the Website, or
			any difficulty or inability to download or access content or any
			other communication system failure which may result in the Website
			being unavailable.
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		To the maximum extent permitted by law, SmartClaim excludes all implied
		warranties in relation to the Website. The Website and software are
		provided “as is” and “as available” without warranty of any kind.
	</p>
	<ol start="5" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Privacy Policy
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		Your use of the Website is governed by our Privacy Policy. Please review
		our Privacy Policy to understand how we collect, use, and disclose
		personal information.
	</p>
	<ol start="6" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Termination
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		We reserve your right to terminate or suspend access to the Website at
		any time, for any reason, including but not limited to any breach of
		these Terms.
	</p>
	<ol start="7" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Disclaimers
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:normal; font-size:10pt"
	>
		<span style="background-color:#ffffff"
			>We aim to provide accurate and up-to-date information, but we
			cannot guarantee the completeness, accuracy, or reliability of the
			content on the Website. No information displayed or shared within
			the Website should be considered as financial or legal advice.</span
		><span style="background-color:#ffffff">&#xa0;</span>
	</p>
	<ol start="8" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Limitation of Liability
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		To the maximum extent permitted by law, SmartClaim shall not be liable
		for any direct, indirect, indirect, incidental, special, consequential
		or punitive damages, or any loss of profits or revenues arising out of
		your use of the Website.
	</p>
	<ol start="9" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:23pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; padding-left:13pt; font-size:10pt; font-weight:bold; background-color:#ffffff"
		>
			Governing Law
		</li>
	</ol>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		These Terms are governed by and construed in accordance with the laws of
		England and Wales, and the English courts shall have exclusive
		jurisdiction over any dispute.
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		&#xa0;
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		<u>Contact Information:</u>
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		If you have any questions or concerns regarding these Terms, please
		contact us at <a
			href="/cdn-cgi/l/email-protection"
			class="__cf_email__"
			data-cfemail="d9aab1b0b5b0b799aab4b8abadbab5b8b0b4f7acb2"
			>[email&#160;protected]</a
		>.
	</p>
	<p style="line-height:115%; font-size:10pt">
		<br style="page-break-before:always; clear:both" />
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		<strong><u>SmartClaim Privacy Policy </u></strong>
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		This privacy policy (“<strong>Privacy Policy</strong>”) gives you
		information about how Smartclaim Technology Ltd (“<strong
			>SmartClaim</strong
		>”) collects and uses your personal data (“<strong>Personal Data</strong
		>”) through your use of this website (“<strong>Website</strong>”),
		including any data you may provide when you register with us.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:normal; font-size:10pt"
	>
		By using the Website, you agree to the collection and use of your
		Personal Data in accordance with this Privacy Policy. Please take the
		time to review this document carefully. If you have any questions or
		concerns, you can contact us using the details provided below.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:normal; font-size:10pt"
	>
		Please note that this Privacy Policy may be updated from time to time.
		Any changes will be posted on this page, and we encourage you to review
		it periodically to stay informed of any updates.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:normal; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			What is Personal Data?
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		‘Personal Data’ means any information about an individual from which
		that person can be identified.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		We may collect, use, store and transfer different kinds of Personal Data
		about you which we have grouped together as follows:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Identity Data</strong> includes first name, last name, any previous
			names, username, title, date of birth and gender.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Contact Data</strong> includes billing address, delivery address,
			email address and telephone numbers.
		</li>
		<li
			style="margin-top:10pt; margin-left:30.78pt; text-align:justify; line-height:115%; padding-left:5.22pt; font-size:10pt"
		>
			<strong>Financial Data</strong> includes bank account and payment card
			details.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Technical Data</strong> includes internet protocol (IP) address,
			your login data, browser type and version, time zone setting and location,
			browser plug-in types and versions, operating system and platform, device
			ID and other technology on the devices you use to access this website.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Usage Data</strong> includes information about how you interact
			with and use our website, products and services.
		</li>
		<li
			style="margin-top:10pt; margin-left:28.56pt; text-align:justify; line-height:115%; padding-left:7.44pt; font-size:10pt"
		>
			<strong>Marketing and Communications Data</strong> includes your preferences
			in receiving marketing from us and our third parties and your communication
			preferences.
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		We also collect, use and share aggregated data such as statistical or
		demographic data which is not Personal Data as it does not directly (or
		indirectly) reveal your identity. For example, we may aggregate
		individuals' Usage Data to calculate the percentage of users accessing a
		specific website feature in order to analyse general trends in how users
		are interacting with our Website to help improve the Website and our
		services.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		It is important that the Personal Data we hold about you is accurate and
		current. Please keep us informed if your Personal Data changes during
		your relationship with us, for example a new email address.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			How is your Personal Data collected?
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		We use different methods to collect data from and about you including
		through your interactions with us.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		You may give us your personal data by filling in online forms or by
		corresponding with us by post, phone, email or otherwise. This includes
		Personal Data you provide when you:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-roman">
		<li
			style="margin-top:10pt; margin-left:59pt; text-align:justify; line-height:115%; padding-left:13pt; font-size:10pt"
		>
			create an account on our website;
		</li>
		<li
			style="margin-top:10pt; margin-left:59pt; text-align:justify; line-height:115%; padding-left:13pt; font-size:10pt"
		>
			subscribe to our services;
		</li>
		<li
			style="margin-top:10pt; margin-left:59pt; text-align:justify; line-height:115%; padding-left:13pt; font-size:10pt"
		>
			request marketing to be sent to you;
		</li>
		<li
			style="margin-top:10pt; margin-left:59pt; text-align:justify; line-height:115%; padding-left:13pt; font-size:10pt"
		>
			give us feedback or contact us.
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		As you interact with our Website, we will automatically collect
		Technical Data about your equipment, browsing actions and patterns. We
		collect this personal data by using cookies, and other similar
		technologies. We comment further on this below.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="3" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			How we use your Personal Data
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		The law requires us to have a legal basis for collecting and using your
		Personal Data. We rely on one or more of the following legal bases:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Performance of a contract with you.</strong> Where we need to
			perform the contract we are about to enter into or have entered into
			with you.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Legitimate interests</strong>. We may use your Personal Data
			where it is necessary to conduct our business and pursue our
			legitimate interests, for example to prevent fraud and enable us to
			give you the best and most secure customer experience.
		</li>
		<li
			style="margin-top:10pt; margin-left:30.78pt; text-align:justify; line-height:115%; padding-left:5.22pt; font-size:10pt"
		>
			<strong>Legal obligation</strong>. We may use your personal data
			where it is necessary for compliance with a legal obligation that we
			are subject to. We will identify the relevant legal obligation when
			we rely on this legal basis.
		</li>
	</ol>
	<p
		style="margin-top:10pt; margin-left:36pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="4" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			Generative AI
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		We may use Generative AI to process your Personal Data, but we will not
		make any important decisions about you based solely on this automated
		processing, especially if it could impact on your legal rights.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		If we do need to use AI for decisions, it will only be where:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			It is necessary to fulfil a contract with you;
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			We are required by law; or
		</li>
		<li
			style="margin-top:10pt; margin-left:30.78pt; text-align:justify; line-height:115%; padding-left:5.22pt; font-size:10pt"
		>
			You've given us permission to do so.
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Even then, we will implement appropriate safeguards to protect your
		rights.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="5" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			International transfers
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		We may transfer your Personal Data to service providers that carry out
		certain functions on our behalf. This may involve transferring personal
		data outside the UK to countries which have laws that do not provide the
		same level of data protection as the UK law.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Whenever we transfer your Personal Data out of the UK to service
		providers, we ensure a similar degree of protection is afforded to it by
		ensuring that the following safeguards are in place:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			We will only transfer your Personal Data to countries that have been
			deemed by the UK to provide an adequate level of protection for
			personal data; or
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			We may use specific standard contractual terms approved for use in
			the UK which give the transferred personal data the same protection
			as it has in the UK.
		</li>
	</ol>
	<p
		style="margin-top:10pt; margin-left:36pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="6" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			Data retention
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		We will only retain your Personal Data for as long as reasonably
		necessary to fulfil the purposes we collected it for, including for the
		purposes of satisfying any legal, regulatory, tax, accounting or
		reporting requirements. We may retain your Personal Data for a longer
		period in the event of a complaint or if we reasonably believe there is
		a prospect of litigation in respect to our relationship with you.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="7" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			Cookies
		</li>
	</ol>
	<p
		style="margin-top:10pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		Cookies are small files that save your settings and record your visits
		to our Website, or any interactions with our communications.&#xa0; We
		may use cookies and similar tracking technologies within our Website to:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-top:10pt; margin-left:31.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			enhance your user experience and for analytics / functional
			purposes; and
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			send you details of services or products which we consider you may
			be interested in (unless you have specifically asked not to receive
			such communications)
		</li>
	</ol>
	<p
		style="margin-top:10pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		You can manage your cookie preferences (including whether we may collect
		cookies for marketing or other promotional purposes) through your
		browser settings, but this may affect how our Website works for you.
	</p>
	<p
		style="margin-top:10pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="8" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			Your legal rights
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		You have a number of rights under data protection laws in relation to
		your Personal Data.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		You have the right to:
	</p>
	<ol style="margin:0pt; padding-left:0pt; list-style-type:lower-latin">
		<li
			style="margin-top:10pt; margin-left:31.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong
				><span style="color:#0d0d0d">Access and Update:</span></strong
			><span style="color:#0d0d0d"> You can ask </span>us for a copy of
			your Personal Data, and ask us to update it where necessary.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Delete:</strong> You can ask us to delete your Personal Data
			and we will, unless we are prevented from doing so by our legal or regulatory
			obligations.
		</li>
		<li
			style="margin-top:10pt; margin-left:30.78pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:5.22pt; font-size:10pt"
		>
			<strong>Opt-Out</strong>: You can stop receiving marketing messages
			by contacting us at the email/postal address below, changing your
			settings for the Website, or clicking the unsubscribe link in any
			message.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Transfer</strong>: You can ask us to provide a copy of your
			Personal Data in a format that you can take to another service.
		</li>
		<li
			style="margin-top:10pt; margin-left:31.34pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt"
		>
			<strong>Complain</strong>: If you are not happy with
			<span style="color:#0d0d0d"
				>how we handle your data, you can complain to us, and if you're
				still not satisfied, you can complain to the data protection
				authority (below).</span
			>
		</li>
	</ol>
	<p
		style="margin-top:10pt; margin-left:36pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<ol start="9" style="margin:0pt; padding-left:0pt">
		<li
			style="margin-top:10pt; margin-left:31.34pt; text-align:justify; line-height:115%; padding-left:4.66pt; font-size:10pt; font-weight:bold"
		>
			Third-party links&#xa0;
		</li>
	</ol>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		This Website may include links to third-party websites, plug-ins and
		applications. Clicking on those links or enabling those connections may
		allow third parties to collect or share data about you. We do not
		control these third-party websites and are not responsible for their
		privacy statements. When you leave our website, we encourage you to read
		the privacy policy of every website you visit.
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<p
		style="margin-top:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		<u>Contact Information </u>
	</p>
	<p
		style="margin-top:10pt; margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		If you have any questions or concerns regarding this policy, please
		contact us at <a
			href="/cdn-cgi/l/email-protection"
			class="__cf_email__"
			data-cfemail="483b2021242126083b25293a3c2b24292125663d23"
			>[email&#160;protected]</a
		>
	</p>
	<p
		style="margin-bottom:10pt; text-align:justify; line-height:115%; padding-top:8pt; font-size:10pt; background-color:#ffffff"
	>
		&#xa0;
	</p>
	<p
		style="margin-right:5pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<p
		style="margin-right:5pt; margin-bottom:10pt; text-align:justify; line-height:115%; font-size:10pt"
	>
		&#xa0;
	</p>
	<div style="clear:both">
		<p class="Footer">&#xa0;</p>
	</div>
</div>
