<script lang="ts">
    import { Section, Contact } from "flowbite-svelte-blocks";
    import { Label, Input, Textarea, Button, Spinner } from "flowbite-svelte";
    import { send_email } from "../service/http_calls";
    import { toast } from "@zerodevx/svelte-toast";

    let loading = false;
    let email = "";
    let subject = "";
    let message = "";
    async function handleSubmit() {
        loading = true;
        await send_email(email, subject, message);
        loading = false;
        toast.push("Thanks for contacting us! We will get back to you soon.");
    }
</script>

<Contact>
    <svelte:fragment slot="h2">Contact Us</svelte:fragment>
    <svelte:fragment slot="paragraph">Get in touch with us</svelte:fragment>
    <form class="space-y-8">
        <div>
            <Label for="email" class="block mb-2">Your email</Label>
            <Input
                id="email"
                name="email"
                placeholder="name@email.com"
                bind:value={email}
                required
            />
        </div>
        <div>
            <Label for="subject" class="block mb-2">Subject</Label>
            <Input
                id="subject"
                name="subject"
                placeholder="Let us know how we can help you"
                bind:value={subject}
                required
            />
        </div>
        <div>
            <Textarea
                id="subject"
                name="subject"
                placeholder="Leave a comment..."
                label="Your message"
                bind:value={message}
            />
        </div>
        {#if loading}
            <div class="flex justify-center">
                <Spinner />
            </div>
        {:else}
            <Button on:click={handleSubmit}>Send message</Button>
        {/if}
    </form>
</Contact>
