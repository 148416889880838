<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Textarea,
    } from "flowbite-svelte";
    import { BadgeCheckOutline, } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import { fetchFiles, getUser } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import Speedometer from "svelte-speedometer";
    import { marked } from "marked";

    let options = {
        thickness: 12,
        legend: "Total",
        color: "orangered",
        bgColor: "#bbb",
        valueOverflow: true,
        textColor: "#ffc0b5",
    };
    onMount(async () => {
        const resp = await fetchFiles("qualify");

        files_list.update( (prev) => {
            prev["qualify"] = resp;
            return prev;
        });

    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="qualify"></FilesTable>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>

            <P size="lg" class="my-5"
                ><Span gradient>Short answers</Span></P
            >

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Project title</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Based on the provided document, which centers on utilizing AI to enhance R&D advisory services, particularly through the SmartClaim platform, here are ten potential project titles for the Innovate UK Smart Grant application:

1. **AI-Driven Innovation: Transforming R&D Advisory Through SmartClaim**
2. **Supercharging R&D Advisory with AI-Powered Claims Processing**
3. **Revolutionizing R&D Consultancy with AI for Enhanced Productivity and Client Growth**
4. **Harnessing AI to Accelerate R&D Claims for Small and Medium Enterprises**
5. **SmartClaim: Boosting Efficiency in R&D Advisory via AI Technology**
6. **AI Innovation in R&D: Streamlining Claim Management for Consultancy Firms**
7. **Enhancing R&D Claims Efficiency Using AI-Powered Automation Tools**
8. **Transforming the R&D Advisory Landscape with AI-Powered SmartClaim**
9. **AI-Enhanced R&D Consulting: Reducing Time and Increasing Client Engagement**
10. **SmartClaim: AI Innovations for Faster, Smarter R&D Claims Management**

These titles aim to reflect both the innovative use of AI and the core objectives of improving R&D advisory processes and client service.
`)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Project summary</span>
**Relevant Fields of Science or Technology**  
Artificial Intelligence (AI), Machine Learning (ML), Natural Language Processing (NLP), R&D Consultancy, Automation, Data Security

**Project Description**  
This project seeks to transform the R&D consultancy industry by integrating AI-driven automation into the claims preparation process through the SmartClaim platform. SmartClaim leverages advanced AI technologies such as machine learning and natural language processing to streamline and accelerate the drafting, reviewing, and defending of R&D claims for businesses. It provides a comprehensive solution that automates labor-intensive tasks, increases productivity, and reduces human error, making it easier for R&D consultants to manage high volumes of claims efficiently.

The project’s AI model analyzes and processes vast amounts of R&D-related data, drafts compliant claims based on the latest HMRC guidelines, and provides real-time recommendations to improve claim quality. This tool enables R&D consultants to handle significantly more claims in less time, freeing them to focus on higher-value client interactions and growth. It also includes built-in data security features, ensuring that client data is protected and remains compliant with EU regulations.

**What Makes It Innovative**  
SmartClaim is revolutionary because it introduces AI-powered automation into a traditionally manual and expertise-heavy industry. While most R&D consultants rely on human-led processes, SmartClaim significantly reduces the time spent drafting and reviewing claims by automating up to 80% of these tasks. This allows consultants to handle more claims, increasing their revenue potential and scalability. By incorporating deep learning algorithms that continuously learn from HMRC guidelines, the platform ensures that the claims produced are not only faster but also highly accurate and tailored to specific requirements.

Another innovation is the system's adaptability. It supports individual consultants and scales seamlessly for larger teams, making it accessible to SMEs and large firms alike. SmartClaim also incorporates real-time collaboration features, allowing multiple consultants to work on a claim simultaneously, further improving efficiency.  

Additionally, SmartClaim’s secure infrastructure prioritizes data privacy, using cutting-edge encryption to safeguard sensitive information, thus providing a trustworthy solution in an era where data security is paramount.

This project is poised to reshape the R&D advisory industry by driving efficiency, improving claim accuracy, and allowing consultants to expand their service offerings through AI-driven innovation.
                    <div class="max-w-full prose">
                        {@html marked(`
  `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Scope</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The proposed project aligns perfectly with the scope of the Innovate UK Smart Grant competition. The project will start in April 2025 and conclude by March 2027, adhering to the required timelines. The lead organisation is a UK-based SME specializing in AI-driven automation for R&D consultancy. The project will be carried out entirely within the UK, and its results will be exploited in the UK, supporting national innovation.

This project fits within the 19-24 month duration category, and the total project costs will fall between £100,000 and £1 million, making it eligible for collaborative funding. We will be working with additional UK-based partners, ensuring a collaborative effort that will enhance the project's overall impact. Collaboration partners will include a research institution and another UK-based SME, all of whom will contribute significantly to the project’s success.

The project is innovative in its use of AI technology to transform R&D consultancy, a traditionally manual and time-consuming process. SmartClaim, the AI platform at the heart of this project, leverages machine learning and natural language processing to automate claim preparation, review, and compliance. By doing so, it dramatically reduces the time required for R&D consultants to process claims, allowing them to focus on high-value activities such as client interaction and business growth. This innovation not only boosts productivity but also enables scalability for R&D firms of all sizes.

Additionally, the project will involve the development of a secure and compliant AI system that protects sensitive data. By ensuring compliance with data privacy regulations, SmartClaim offers a robust and secure solution, making it suitable for widespread adoption in the UK’s R&D consultancy sector.

The project fully complies with all eligibility criteria, including collaboration requirements and funding regulations. Its focus on AI-driven automation and data security addresses a significant market need, making it a compelling candidate for Innovate UK's support. Through this project, we aim to enhance the UK’s global position in AI-driven innovations and R&D advisory services.
   `)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Public description</span>
                    <div class="max-w-full prose">
                        {@html marked(`
This project aims to revolutionize the R&D advisory sector by developing and deploying SmartClaim, an AI-powered platform designed to automate the preparation and management of R&D tax claims. Currently, R&D consultants rely on manual processes to draft, review, and submit claims, which is time-consuming and prone to human error. SmartClaim leverages advanced AI technologies, including machine learning and natural language processing, to automate up to 80% of these tasks, significantly reducing the time and effort required.

SmartClaim is designed to process vast amounts of data, ensuring compliance with the latest HMRC guidelines, while also offering real-time recommendations to enhance claim accuracy and quality. By automating repetitive tasks, the platform allows R&D consultants to focus more on client relationships and growing their businesses, helping them handle more claims without compromising on quality.

The platform will be secure and compliant with UK data protection laws, using state-of-the-art encryption to protect sensitive client information. SmartClaim is scalable, meaning it can be used by individual consultants as well as large teams, and its real-time collaboration feature allows multiple users to work on claims simultaneously, improving efficiency and accuracy.

By increasing the productivity of R&D consultants and allowing them to manage higher volumes of claims, SmartClaim has the potential to drive significant growth in the UK’s R&D advisory sector. It is designed to benefit not only the consultants but also their clients by streamlining the claims process and ensuring faster, more accurate submissions.
   `)}
                    </div>
                </AccordionItem>

            </Accordion>

            <P size="lg" class="my-5"
                ><Span gradient>Long answers</Span></P
            >
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Your idea and innovation</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Our project seeks to develop and deploy *SmartClaim*, an AI-powered platform designed to revolutionize the R&D consultancy sector by automating the preparation, review, and defense of R&D tax claims. This innovation addresses the inefficiencies and time-intensive nature of current processes, which are highly manual, error-prone, and dependent on individual consultant expertise. With SmartClaim, we are bringing a cutting-edge AI solution that is truly game-changing for the R&D advisory industry.

### Specific Innovation
SmartClaim will leverage advanced machine learning (ML), natural language processing (NLP), and deep learning technologies to automate up to 80% of the R&D claims process. It will analyze large volumes of data, identify patterns, and align claim submissions with HMRC guidelines in real-time. The platform automates the drafting of claims, provides real-time compliance checks, and suggests improvements based on historical data. Furthermore, it includes secure, cloud-based collaboration tools that allow multiple consultants to work on a claim simultaneously, boosting efficiency across teams.

### Differentiation from Available Solutions
SmartClaim is different from any existing solution because it combines automation with AI-driven insights specifically tailored for R&D tax claims. While there are a few tools that assist in claim submissions, they focus mainly on compliance checking or template-based assistance. These solutions still require substantial manual input, offering no real automation of the drafting or review process.

In contrast, SmartClaim will automate the majority of claim preparation by learning from previous claims, continuously updating its model based on new HMRC guidelines, and using AI to suggest optimized wording and structuring of claims. Its deep learning component ensures that it adapts over time, making the system smarter and more efficient with each use, something that no current solution offers.

### New Solutions in an Existing Area
While R&D consultancy is an established field, SmartClaim introduces a radically new approach to handling tax claims. We are not merely improving an existing tool; we are fundamentally transforming how R&D claims are created and managed. By embedding AI directly into the process, we are setting a new standard for the industry, offering faster, more accurate, and scalable solutions to handle the increasing complexity of R&D tax submissions.

### Addressing the Specific Need
The primary need we are addressing is the inefficiency and error-prone nature of the current R&D claims process. Consultants spend considerable time manually drafting and reviewing claims, leading to bottlenecks in productivity, limited scalability, and increased operational costs. These challenges are particularly acute for SMEs and smaller consultancy firms that lack the resources to handle large volumes of claims efficiently.

SmartClaim directly addresses this need by automating the claim drafting, review, and compliance process. R&D consultants will immediately benefit from faster turnaround times and greater accuracy in their submissions, allowing them to scale their operations without needing to increase staff. In the longer term, the UK economy will benefit from a more efficient R&D tax claim process, encouraging more businesses to claim R&D tax credits and thus driving innovation across sectors.

### Progress to Date
To date, we have developed the foundational AI models and conducted initial testing with a small group of R&D consultants. These early tests have demonstrated the potential to reduce claim preparation time by up to 70%, with significant improvements in accuracy and compliance. Our next step is to refine the platform, incorporating more data to improve our ML models, and rolling out a pilot program with a broader group of consultants to validate the system at scale.

### Equality, Diversity, and Inclusion (EDI)
We are committed to embedding equality, diversity, and inclusion in the project. SmartClaim’s user interface is designed to be accessible to all, including those with disabilities, ensuring it can be used by a diverse range of individuals. Additionally, our cloud-based platform supports flexible and remote work, enabling greater inclusivity in the workplace by allowing consultants to contribute from any location, at any time. This flexibility is particularly beneficial for those balancing family care, disabilities, or other personal commitments, making the industry more inclusive.

### Game-Changing Potential
SmartClaim represents a step-change in how R&D claims are managed. By automating up to 80% of the process, it empowers consultants to focus on higher-value activities, improves the accuracy of claims, and accelerates the turnaround time. This will not only increase the capacity for R&D consultants to take on more clients but also democratize access to efficient R&D tax claim services, benefiting SMEs across the UK. We believe that SmartClaim has the potential to become the industry standard, driving a new era of efficiency and innovation in the R&D consultancy sector.

 `)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Justification for funding</span>
                    <div class="max-w-full prose">
                        {@html marked(`
We believe that Smart funding is the ideal and necessary route for our SmartClaim project due to its game-changing potential and the clear benefits public funding would bring to its success.

### Journey to Grant Readiness
Our team has diligently prepared for this grant application. We have conducted extensive market research, engaged in R&D tax consultancy with industry veterans, and explored the technical feasibility of our platform by developing a prototype. We’ve also sought expert mentoring through industry networks, such as The R&D Community, to ensure our solution is viable and addresses a real market need. These efforts have led us to the point where we are ready to scale and refine our product through Smart funding.

### Exploring Other Investment Routes
We have considered several alternative funding options. Private venture capital was explored, but investors either demanded equity that would compromise the long-term integrity of the company or were risk-averse to funding a platform still in the development stage. We also investigated other public sector funding opportunities, but none were as well-suited as Smart grants, which uniquely support transformative, high-risk projects like ours. Due to the complexity of developing AI systems and the need to stay at the forefront of innovation, traditional lending routes proved inappropriate, as they did not provide the de-risking element we need at this stage.

### Why Smart Funding is the Right Route
Smart funding is essential for this project because it provides the opportunity to fund ambitious and disruptive technologies. By securing this grant, we can accelerate the final development phases of SmartClaim, enabling us to deploy it to market faster. The grant will also de-risk the project, allowing us to attract further investment from private sources without giving up control or compromising our vision. It will enable us to focus resources on optimizing our AI models, ensuring compliance, and scaling the platform for wider adoption.

### What Happens if Funding is Not Secured
Without Smart funding, the project’s progress will be significantly delayed. We would be forced to seek further private investment, which may limit our ability to retain ownership and control of the project. This delay would put us behind competitors, many of whom are also beginning to explore AI-driven automation in the R&D tax consultancy space. Additionally, without public funding, the potential for collaboration with academic institutions and key industry players will be reduced, as these partnerships often depend on de-risked, grant-funded projects.

### Unique Advantages of Public Funding
Public funding offers clear advantages for this project. First, it will enable partnerships with other UK-based R&D consultants and academic institutions, helping us validate the system at scale. It will also allow us to leverage the credibility of Innovate UK’s backing, making it easier to attract further private sector investment at more favorable terms. Additionally, Smart funding will help us mitigate risks associated with the early stages of commercializing a complex AI product, allowing us to focus on innovation rather than short-term financial pressures.

Public funding is also justified as it will benefit not only our company but the wider UK economy by improving the efficiency and scalability of R&D consultancy firms. This will empower more SMEs to access R&D tax credits, ultimately boosting innovation across multiple industries. By reducing the costs and barriers associated with claiming R&D tax credits, SmartClaim has the potential to increase national productivity and innovation, making public funding an investment with broad-reaching impacts.

In conclusion, Smart funding is crucial to ensure that SmartClaim reaches its full potential. It will enable us to deliver a groundbreaking solution to the UK R&D advisory market, driving significant benefits for consultants, SMEs, and the economy at large.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">The potential market</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The target market for SmartClaim is the UK’s R&D tax consultancy sector, which handles thousands of claims annually. This sector consists of over 1,000 R&D consultancy firms, ranging from micro-businesses to large consultancies. The UK R&D tax credit market is growing, with over £7.4 billion in tax relief claimed by UK businesses in 2021-2022, according to HMRC data. Our immediate target is the 100-400 active R&D consultants who serve SMEs, who often face bottlenecks due to time-consuming, manual claims processes. Over time, we aim to expand into larger consultancies and international markets, particularly in Europe and North America, where similar R&D tax relief schemes exist.

### Market Structure and Dynamics
The R&D tax consultancy sector is highly competitive, and consultants are under increasing pressure to handle large volumes of claims efficiently. Currently, most consultants rely on manual processes or simple automation tools that focus only on compliance checking. However, none provide end-to-end automation for claim preparation, which is where SmartClaim stands out. With AI-driven automation, SmartClaim will significantly reduce the time required to process claims, allowing consultants to serve more clients without compromising accuracy or quality.

According to market research, the global R&D tax credit software market is expected to grow at a compound annual growth rate (CAGR) of 10% over the next five years, driven by increased adoption of digital solutions. This trend positions SmartClaim to capture a significant portion of the domestic market and expand internationally as more businesses seek automated solutions to handle the growing complexity of R&D claims.

### Value Proposition and Commitment from Buyers
SmartClaim offers a clear value proposition to R&D consultants: faster processing times, improved claim accuracy, and scalability without additional staff. We have already conducted interviews with R&D consultants, who have expressed strong interest in adopting the platform. Early pilot tests have shown a 70% reduction in time spent on claim preparation, with no loss in accuracy, demonstrating clear return on investment (ROI) potential. Additionally, SmartClaim offers seamless integration into existing workflows, minimizing the learning curve for users.

### Route to Market and Commercialisation
Our route to market will initially focus on direct sales to R&D consultancy firms through digital marketing and industry partnerships. We will use a subscription-based business model, offering tiered pricing depending on the number of claims processed. For larger consultancies, we will offer bespoke solutions, including advanced features and API integration. We are also exploring strategic partnerships with industry networks such as The R&D Community to help reach more potential clients. Over time, we will expand into international markets by adapting the platform to comply with different countries' R&D tax credit regulations.

### Barriers to Entry and Freedom to Operate
Barriers to entry include the need to build trust in an AI-driven solution within a traditionally manual industry. We will address this by providing comprehensive training, customer support, and offering a pilot period with a satisfaction guarantee. Additionally, our secure platform is designed to comply with UK data protection laws, giving users confidence in data security. We have also ensured freedom to operate by conducting an intellectual property (IP) review to confirm there are no existing patents that would limit our ability to bring SmartClaim to market.

### Export Potential
There is significant export potential for SmartClaim, particularly in countries with R&D tax relief programs like Canada, Australia, and the US. We will explore these markets after establishing a strong presence in the UK, leveraging our scalable cloud-based infrastructure to adapt to international requirements.

In summary, SmartClaim addresses a growing market need with a disruptive innovation that will offer significant ROI to customers while having the potential to expand internationally.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Impact and benefits</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Receiving this grant will have a transformative impact on both our business and the broader R&D consultancy sector. The outputs of our project will include the development of the SmartClaim platform, which will automate the preparation and management of R&D tax claims, significantly reducing time and costs for consultants. These outputs include a fully operational AI-driven software platform, demonstrators for clients, and a wealth of know-how related to automating complex R&D processes. 

### Impact on Business and Industry
For our business, SmartClaim is a natural evolution of our current service offerings, which focus on R&D advisory. By integrating advanced AI automation, we will create a new revenue stream through a subscription-based SaaS model, expanding our customer base beyond traditional consultancy services. This innovation will immediately increase our productivity, allowing us to handle more clients without scaling our team at the same rate, resulting in higher margins. Within 12 months of launch, we anticipate a 50% growth in revenue, driven by our ability to serve more clients at reduced operational costs.

In the short term, we expect to capture 10% of the UK R&D consultancy market within two years of commercial launch. In the long term, this innovation will enable us to expand internationally, particularly into markets like Europe and North America, which also have similar R&D tax credit schemes. With international expansion, we expect to see sustained growth of 15% annually over the next five years.

### Job Creation and Safeguarding
The development of SmartClaim will create new roles within our organization, particularly in software development, AI training, and customer support. We anticipate hiring 10 new staff members within the first two years of the project. Additionally, the automation of the claims process will safeguard jobs in the consultancy sector by making firms more efficient, helping them cope with increasing demand without overextending resources. By empowering R&D consultants with more effective tools, we can help them scale their businesses, ensuring job retention across the industry.

### Protecting and Exploiting Project Outputs
We will protect SmartClaim's intellectual property through a combination of patenting key AI algorithms and securing trade secrets related to the platform’s functionality. We will also leverage a subscription-based pricing model, offering different tiers depending on the size of the consultancy firm, ensuring a recurring revenue stream. This model allows us to continually improve and update the platform, making it adaptable and competitive over time. 

### Long-term Impacts on the UK Economy and Society
SmartClaim’s long-term impact on the UK economy will be significant. By streamlining the R&D tax claim process, more businesses will be encouraged to claim these credits, which in turn incentivizes further investment in R&D activities across the UK. This will drive innovation and productivity across multiple sectors, helping the UK maintain its competitive edge in global markets.

Moreover, as R&D tax credits become easier to access through automation, more SMEs will be able to unlock funding for innovation, resulting in broader societal benefits. These businesses will be able to invest in new technologies, create jobs, and contribute to economic growth. SmartClaim will also contribute to environmental sustainability by reducing the administrative overhead and associated resource use in the consultancy process, leading to a reduction in paper usage and unnecessary travel.

### Regional and Societal Impact
The project will have a strong regional impact, particularly in areas where R&D consultancy firms are concentrated. By creating high-skilled jobs in software development, AI, and customer service, we will support local economies and contribute to the UK’s leveling-up agenda. We will also ensure that SmartClaim’s design is inclusive and accessible, with features designed to cater to diverse user needs, including those with disabilities, thus supporting a more equitable work environment.

### Mitigating Negative Impacts
We recognize that automation could be perceived as a threat to traditional consultancy jobs. However, we are confident that SmartClaim will enhance, not displace, jobs by empowering consultants to focus on higher-value activities and scale their operations efficiently. We will provide comprehensive training and support to ensure a smooth transition to AI-enhanced consultancy workflows, ensuring that consultants can thrive in this new environment.

In conclusion, SmartClaim will deliver significant economic, social, and environmental benefits, both within our business and across the UK. By enabling more businesses to access R&D tax credits, it will drive innovation and growth across the country.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Delivering your project</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The SmartClaim project team is highly experienced and possesses the technical, managerial, and operational expertise necessary to deliver the project successfully.

### Project Team and Skills
The core project team consists of experts with deep knowledge in AI development, R&D consultancy, and project management.

- **Shilin Chen, Co-founder & CEO**, leads the team with extensive experience in AI product development and strategy. He previously worked on AI products for HMRC Compliance and has led complex projects in management consulting. His role will focus on overseeing the project’s strategic direction and ensuring alignment with business goals.
  
- **Amin Dorostanian, Co-founder & CTO**, brings over 15 years of software engineering expertise, including the development of AI products for global companies such as BMW and ABN AMRO. He will manage the technical aspects of the project, including AI model development, system architecture, and software integration.
  
- **Mick and Jay, R&D claim consultants**, contribute around 25 years of combined experience in R&D claim management. As design partners, they ensure that SmartClaim aligns with industry standards and meets the practical needs of R&D consultants.

- **Project Manager (to be recruited)** will oversee the day-to-day execution of the project, ensuring timelines, resources, and risks are managed effectively. They will bring specific expertise in managing R&D technology projects to ensure seamless delivery.

Our team is diverse in terms of skills, experience, and backgrounds, which enhances our approach to problem-solving and innovation. We are committed to promoting equality, diversity, and inclusion in our hiring practices, ensuring that future hires reflect this commitment.

### Capability to Deliver
We are confident in our ability to deliver this project within the required timeframe. Our prior experience in delivering AI projects with tight deadlines, alongside the established relationships with external experts, gives us the operational resilience to meet key milestones. We have a clear project plan that outlines critical paths, interdependencies, and timelines to avoid delays.

### Resources, Equipment, and External Support
The SmartClaim project will require access to cloud infrastructure for data processing, AI model training, and software development. We have already secured partnerships with cloud service providers such as Azure, which ensures we have the necessary technical resources to proceed.

Additionally, we will collaborate with external subcontractors for specialized AI model training and cybersecurity to ensure our platform remains secure and compliant with data protection regulations. These tasks require niche expertise not available internally but are critical to the project’s success.

### Project Management Approach
We will employ industry-standard project management tools such as Jira and Trello to track progress, manage tasks, and monitor risks. Weekly sprints will be conducted to maintain momentum and ensure any potential blockers are addressed promptly. Our risk register is comprehensive, identifying technical, financial, and operational risks along with mitigation strategies to prevent delays and ensure timely delivery.

### Post-Project Outcomes
Beyond the life of the project, we are focused on rapid commercialization. We already have a marketing plan to onboard early adopters through pilot programs, followed by a wider roll-out across the UK. Our SaaS model will ensure continuous revenue, allowing us to reinvest in the platform and scale internationally. We have identified key partnerships with industry networks that will facilitate swift entry into new markets.

The project will have a lasting impact on the UK’s R&D consultancy sector, enabling firms to improve efficiency, reduce operational costs, and increase their client base.

In conclusion, our team’s depth of experience, structured project management approach, and robust risk mitigation plan ensure that we are well-positioned to deliver the SmartClaim project successfully. We are prepared to overcome any challenges and deliver impactful outcomes that will drive the commercialization of the platform.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Value for money</span>
                    <div class="max-w-full prose">
                        {@html marked(`
The grant funding for the SmartClaim project will be spent strategically to ensure maximum value for money, both in terms of project delivery and long-term economic impact. 

### Funding Allocation
The funding will be distributed across key work packages, including AI development, software engineering, user testing, and market readiness. Approximately 40% of the budget will be dedicated to AI model training and software development, ensuring we build a robust and scalable platform. This includes costs for cloud infrastructure, data processing, and licensing for key software tools. Another 30% will be allocated to user interface (UI) and user experience (UX) design, focusing on creating an intuitive platform that meets the needs of R&D consultants. A further 15% will go towards market testing and pilot programs, while the remaining 15% will be reserved for project management and contingency, allowing flexibility for unforeseen challenges.

### Justifying Best Value for Money
We have rigorously assessed costs through a comparison of multiple quotations from subcontractors and service providers to ensure competitive pricing without compromising quality. For example, we’ve selected a UK-based AI consultancy with a strong track record in R&D tax claims automation to handle the more technical aspects of AI model development. Subcontractor costs for cybersecurity will also be tightly controlled, with providers selected through a competitive tender process to ensure both affordability and high standards.

### Partner Contributions and Match Funding
Our project partners will contribute to the project through a combination of internal resources and financial investment. Each partner has committed match funding, which is already secured, ensuring we can meet the earliest project start date as per competition criteria. Our match funding will cover in-house development and management costs, reducing reliance on external providers and lowering overall project costs. This demonstrates the consortium’s commitment to the project and ensures that all parties are financially aligned with the project's success.

### Post-Project Funding and Commercialisation
Post-project activities, including scaling the platform, onboarding new clients, and pursuing international expansion, will be funded through revenue generated from the initial SmartClaim platform launch. Our subscription-based SaaS model ensures early revenue generation, which will be reinvested into ongoing development and platform improvements. We have already identified potential private sector investors and are exploring partnership opportunities with venture capital firms to support further growth beyond the project’s lifespan.

### Wider Support and Partnerships
We will leverage export advice from the Department for Business and Trade to enter international markets, particularly in Europe and North America, where similar R&D tax credits exist. We will also seek support from Innovate UK EDGE for guidance on scaling and strategic growth. These partnerships will help us mitigate risks, accelerate market entry, and maximize the impact of public funding.

In summary, our costs are appropriately allocated to ensure efficient project delivery, and we have secured match funding to support all work packages. The SmartClaim project represents excellent value for money for the taxpayer by delivering a high-impact, scalable solution that will drive productivity and innovation across the UK’s R&D consultancy sector.
`)}
                    </div>
                </AccordionItem>

            </Accordion>



            <div class="mt-5 flex justify-end"></div>
        </Card>
    </div>
</div>
