<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner
    } from "flowbite-svelte";
    import { BadgeCheckOutline, FlagOutline } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import {
        fetchFiles,
        getUser
    } from "../service/http_calls";
    import {
        authenticated_user,
        current_user,
        files_list
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import { marked } from "marked";

    let loading_ingest = false;


    onMount(async () => {
        const resp = await fetchFiles("review");

        files_list.update( (prev) => {
            prev["review"] = resp;
            return prev;
        });

});
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="review"></FilesTable>
            <div class="flex justify-end my-5">
                {#if loading_ingest}
                    <Spinner></Spinner>
                {:else}
                    <Button size="sm" color="purple">Submit</Button>
                {/if}
            </div>

            <P size="lg" class="my-5"
                ><Span gradient>Review</Span></P
            >
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Your idea and innovation</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Score: 7/10**

**Rationale:**
- The proposal outlines a clear and technically sound innovation (multi-tiered AI architecture for educational assessments).
- There is a strong emphasis on using large language models (LLMs) tailored for education, which shows merit and potential for game-changing impact, especially with its focus on personalisation and bias reduction.
- Differentiation from competitors is provided, but lacks clarity in detailing how significantly KEATH.ai surpasses these existing solutions.
- The understanding of the state of the art (current alternatives) is somewhat vague, and although examples are mentioned, a more thorough comparison would strengthen the application.
- Social and economic benefits are well-articulated, addressing issues such as teacher workload and improving job satisfaction.
- The section on equality, diversity, and inclusion (EDI) is mentioned but lacks depth in how it would specifically be embedded into the system's development beyond bias reduction.

**Action-oriented Feedback for a Higher Score:**
1. **Expand on Differentiation from Competitors:** Provide a clearer, more detailed comparison of KEATH.ai’s innovation against specific competitors, focusing on how it uniquely addresses gaps or limitations in the current state-of-the-art solutions. Use more concrete examples to show how this solution is ahead.
2. **Clarify the Game-changing Impact:** While the technology is described as transformative, additional clarity is needed on why the layered AI architecture is fundamentally new or groundbreaking in comparison to existing AI-based educational tools. Show how it dramatically enhances current solutions rather than incrementally improving them.
3. **State of the Art Analysis:** Provide a more structured analysis of the nearest state of the art, with stronger evidence or metrics supporting how the innovation builds on or differs from these solutions.
4. **Enhance EDI Strategy:** Detail specific actions or features that embed EDI more deeply into the system beyond bias mitigation, such as how the system will handle different educational contexts or student needs, including disadvantaged groups.
5. **Evidence of Commercial Viability:** Strengthen the proposal by including a more detailed explanation of the market readiness and commercial strategy. This will provide confidence in the project's sustainability and scalability.

Incorporating these improvements will help make the application clearer and more compelling, increasing its score potential.
 `)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Justification for funding</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Score: 7/10**

**Rationale:**
- The application presents a reasonable case for Smart funding, explaining the strategic choice of avoiding private investment to focus on research and refinement.
- The project’s alignment with university partnerships and real-world trials enhances its credibility, showing readiness for grant funding.
- The potential impact of the project, including job creation and the benefits to the education sector, is clear, but lacks specific details on why public funding is the only suitable route.
- There is some exploration of alternative funding options, but it is not deeply explained why private or other public sector funding is unsuitable.
- The argument for how Smart funding would de-risk the project and accelerate its development is solid but could be expanded with more concrete evidence.

**Action-oriented Feedback for a Higher Score:**
1. **Clarify the Funding Rationale:** Strengthen the explanation of why other funding routes (both public and private) are unsuitable, giving more detail on why equity dilution or alternative public grants were not chosen. Provide examples of specific funding options that were considered and rejected.
2. **Deepen Justification for Public Funding:** Expand on why Smart funding is the optimal choice beyond accelerating development and de-risking. For instance, detail how Smart funding uniquely fosters the required collaborations and ensures sustainability for long-term goals in ways that other funding would not.
3. **Enhance the Impact Description:** Provide more specific, quantifiable details on the impact that Smart funding would have, such as the expected timeline for job creation or the projected timeline reduction in technology development. This could include more details on milestones and how Smart funding will directly contribute to achieving them.
4. **Expand on Market Readiness:** Include more detail about the current stage of the product’s development and market readiness, showing that the project is at the perfect stage for Smart funding support to significantly boost its trajectory.
5. **Risk Mitigation:** Offer clearer insights into the risks associated with delaying the project by 24 months without Smart funding and how public funding uniquely mitigates these risks, particularly in terms of research, partnerships, and scaling.

Incorporating these changes will make the case for Smart funding more compelling and help clarify the project’s specific funding needs.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">The potential market</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Score: 7/10**

**Rationale:**
- The application demonstrates a good understanding of the target market, particularly the education sector, and quantifies the market size with some supporting evidence.
- The B2C and B2B market segments are well-defined, and the value proposition is clearly articulated, addressing key pain points such as teacher workload and marking efficiency.
- There is a general projection of revenue, but the assumptions regarding market penetration and adoption rates need more substantiation.
- Competitive analysis is touched upon but lacks detailed comparison with the main competitors, limiting the clarity on how KEATH.ai’s offering is substantially better.
- Barriers to entry are identified, but the plan for overcoming these (AI bias concerns and technology adoption) could benefit from further elaboration.
- Export potential is briefly mentioned, but a more detailed strategy for global market entry is missing.

**Action-oriented Feedback for a Higher Score:**
1. **Provide More Detailed Market Data:** While the market size is mentioned, a more in-depth analysis of the trends, growth rates, and specific dynamics of both the UK and global education technology market would strengthen the case. This could include data on the increasing adoption of AI tools in education or the financial pressures institutions face, providing context for the demand.
2. **Substantiate Revenue Projections:** Further detail on how the B2C and B2B adoption rates (10% for schools, 20-30% for universities) were calculated would be helpful. Use evidence from pilot studies, market research, or competitor analysis to back up these figures and make the projections more realistic.
3. **Expand on Competitor Analysis:** While competitors like Turnitin and Grammarly are mentioned, a deeper comparison on specific features, pricing, and user outcomes would illustrate how KEATH.ai’s multi-layered LLM approach offers a more compelling solution.
4. **Strengthen Barriers to Entry Strategy:** Elaborate on how the company plans to tackle AI bias concerns and technology adoption more effectively. For instance, detail the user education plan around NeMo Guardrail technology and describe specific partnerships for user training.
5. **Detail the Export Strategy:** The potential for global market entry is acknowledged, but more clarity on which markets KEATH.ai will target first, and how it plans to adapt to different educational systems, would make the export plan more robust.

By incorporating more data-driven insights, refining projections, and expanding on competitive positioning and barriers, the proposal would present a stronger case for market readiness and commercial viability.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Impact and benefits</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Score: 7/10**

**Rationale:**
- The application presents a well-rounded overview of the potential impacts, both internally and externally, with clear benefits for educators, students, and the broader educational sector.
- The integration of new capabilities into existing services is mentioned, but there is a lack of detailed, quantifiable evidence to support the scale of market penetration and impact.
- Short-term and long-term benefits are outlined, including productivity improvements and job creation, but timelines for realisation could be clearer.
- There is an awareness of the broader societal benefits, such as addressing staff retention and student satisfaction, but the environmental and regional impacts need further development.
- The mitigation of negative impacts (e.g. administrative job displacement) is addressed, but the strategy for retraining and transitioning staff requires more clarity.

**Action-oriented Feedback for a Higher Score:**
1. **Provide More Quantifiable Evidence of Impact:** While the benefits of reduced marking workload and improved efficiency are stated, more detailed metrics on how this will affect schools, universities, and private tutors would strengthen the argument. For example, how many hours will be saved per week, and how will this translate into tangible improvements in staff retention or student performance?
2. **Clarify Timelines for Benefit Realisation:** Include specific milestones for when key outcomes (e.g. market penetration, revenue growth, or job creation) are expected to be achieved, particularly in the first year and over the next five years. This will add more precision to the projected impacts.
3. **Expand on Regional and Environmental Impacts:** Provide more detailed insights into how KEATH.ai will positively affect specific regions or areas in the UK. Similarly, expand the section on environmental benefits, offering more detailed information on how reduced computing requirements and lower energy consumption will be achieved.
4. **Deepen Negative Impact Mitigation Plans:** The retraining of staff displaced by automation is briefly mentioned but should be expanded with more details about partnerships with institutions for this retraining, timelines, and the resources needed to execute it.
5. **Further EDI Considerations:** While accessibility and bias reduction are mentioned, more specific examples of how KEATH.ai will cater to different user groups and avoid unintended exclusion of minority groups should be provided.

By incorporating more evidence-backed projections and providing further details on timelines, environmental impact, and EDI strategies, the application could score higher.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Delivering your project</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Score: 6/10**

**Rationale:**
- The project team is composed of individuals with relevant expertise, particularly in AI, machine learning, education, and project management, which gives confidence in the team's ability to deliver.
- Key roles, such as the CEO, CTO, and academic partners, bring valuable skills to the table, but more clarity is needed on how they will manage complex project risks and timelines.
- The diversity of the team is briefly addressed, but there is a lack of detail on how this diversity will influence project delivery or innovation.
- The project implementation plan and risk register are mentioned, but there is limited explanation provided in the core application about specific risks and their mitigation strategies.
- Post-project plans for global expansion and talent recruitment are discussed, but these are described at a high level without concrete timelines or milestones.

**Action-oriented Feedback for a Higher Score:**
1. **Provide More Detail on Risk Management:** The risk register should include key risks, such as technical failures, team resourcing, or market acceptance, with clear mitigation strategies. Adding this detail directly into the core application, rather than solely relying on the appendix, would improve clarity.
2. **Strengthen the Project Plan Overview:** More information is needed on how the team will track project progress and ensure timely delivery. Provide an overview of the GANTT chart in the main text, showing key milestones and how dependencies between work packages will be managed.
3. **Expand on Diversity and Recruitment Plans:** While the team’s diversity is mentioned, it would be helpful to explain how this diversity enhances innovation and delivery. More detail on recruitment strategies, including timelines and processes to promote equality, diversity, and inclusion (EDI), would also strengthen this section.
4. **Clarify Post-Project Commercialisation:** The post-implementation strategy could be enhanced with clearer timelines, specific goals for global expansion, and measurable objectives for market penetration. This would add depth to the commercialisation plan and ensure confidence in the scalability of the project.
5. **Highlight Collaboration Strengths:** The relationships between consortium members (Surrey and Southampton universities) should be more explicitly detailed in terms of how they will contribute to specific project workstreams and ensure successful delivery.

By elaborating on project risks, management strategies, and providing a clearer roadmap for post-project commercialisation, this application could improve its score significantly.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Value for money</span>
                    <div class="max-w-full prose">
                        {@html marked(`
**Score: 7/10**

**Rationale:**
- The project costs appear reasonable and aligned with the proposed work, with a clear breakdown of expenses across labour, materials, and overheads for each consortium partner.
- KEATH.ai's contribution of matching funds demonstrates commitment, and the project partners (Southampton and Surrey) are providing labour and material resources that enhance project value without additional costs.
- The explanation of value for money, including competitive pricing for cloud-based AI solutions, strengthens the case for cost-efficiency.
- However, the justification for specific costs (e.g., materials like Norton Security and Azure Storage) could benefit from more detail, particularly on how costs were determined (e.g., quotation comparisons).
- Post-project commercialisation plans are discussed, but they could be more detailed, particularly in terms of how ongoing operations will be sustained financially and the scale of market adoption.

**Action-oriented Feedback for a Higher Score:**
1. **Provide More Details on Cost Justification:** While the breakdown of costs is presented, the rationale behind these costs, such as the choice of specific materials and tools, could be clearer. Include more information on how best value was ensured (e.g., competitive quotations or market comparisons).
2. **Elaborate on Post-Project Funding:** The post-project funding and commercialisation strategy is mentioned but could be more thoroughly explained. Detail how projected revenues from universities, schools, and private tutors will be achieved and provide timelines for financial sustainability.
3. **Include More Specific Evidence of Value for Money:** Provide more examples of how cost-efficiency will be achieved, such as by outlining specific negotiations, bulk purchasing discounts, or resource-sharing arrangements with the partner universities.
4. **Clarify Subcontractor Costs:** If any subcontractor costs are involved, explain how they were calculated and justify their inclusion to ensure transparency and clarity for reviewers.
5. **Expand on International Market Plans:** The international expansion to Bangladesh and the UAE is mentioned but should include more concrete steps, financial projections, and timelines to demonstrate that these plans are realistic and aligned with the project's value for money.

By adding more detailed cost justification and improving clarity on post-project financial sustainability, the application would better demonstrate exceptional value for money.
`)}
                    </div>
                </AccordionItem>

            </Accordion>


        </Card>
    </div>
</div>
