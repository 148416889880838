<script lang="ts">
  import { Spinner } from "flowbite-svelte";
  import { get_upload_url } from "../service/http_calls";
  import { files_list } from "../service/store";
  import { toast } from "@zerodevx/svelte-toast";
  export let module;

  let selectedFiles: File[] = [];
  let generating_url = false;

  // Function to upload files one by one
  async function uploadFiles() {
    if (selectedFiles.length === 0) return;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      files_list.update((prev) => {
        prev[module].push({
          name: file.name,
          status: "Uploading",
          content: "",
          upload: 0,
          id: -1,
        });
        return prev;
      });
      try {
        // Step 1: Get the presigned upload URL from the backend
        generating_url = true;
        const uploadUrl = (await get_upload_url(file.name, module)).upload_url;
        generating_url = false;

        // Step 2: Upload the file to S3 using XMLHttpRequest
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", uploadUrl, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percent = (event.loaded / event.total) * 100;
            files_list.update((prev) => {
              let found = prev[module].find((item) => item.name === file.name);
              if (found) {
                found.upload = Math.round(percent);
              } else {
                files_list.update((prev) => {
                  prev[module].push({
                    name: file.name,
                    status: "Uploading",
                    content: "",
                    upload: Math.round(percent),
                    id: -1,
                  });
                  return prev;
                });
              }
              return prev;
            });

            if (Math.round(percent) == 100) {
            }
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            // toast.push("Upload successful! Please wait while we process the file for you...");
          } else {
          }
        };

        xhr.onerror = () => {};

        xhr.send(file);
      } catch (error: any) {
        toast.push("Something went wrong! Check console!");
        console.error(error);
      }
    }
  }

  // Handle file selection
  const handleFileChange = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      selectedFiles = Array.from(target.files);
      // Reset upload states when new files are selected
      await uploadFiles();
    } else {
      selectedFiles = [];
    }
  };
</script>

<div class="rounded-md shadow-md">
  <div class="mb-4 p-5">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="file">
      Select Files
    </label>
    <input
      type="file"
      id="file"
      accept=".pdf,.docx,.mp4,.mp3,.png,.jpg,.jpeg"
      multiple
      on:change={handleFileChange}
      class="block w-full text-sm text-gray-500
             file:mr-4 file:py-2 file:px-4
             file:rounded-full file:border-0
             file:text-sm file:font-semibold
             file:bg-blue-50 file:text-blue-700
             hover:file:bg-blue-100"
    />
  </div>

  {#if generating_url}
    <div class="mb-20 pb-20 flex justify-center">
      <Spinner></Spinner>
    </div>
  {/if}
</div>

