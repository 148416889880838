<script>
    import { toast } from "@zerodevx/svelte-toast";
    import { Accordion, Button, Card, P, Span, Spinner, Dropdown, DropdownItem, Modal, Textarea } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { ask_llm, deleteAllFiles, download_file, fetchFiles } from "../service/http_calls";
    import { files_list, qualify, qualify_add_instructions_modal_open, qualify_initial,  selected_module, selected_section, selected_sub_section } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import ResponseComponentQualify from "./ResponseComponentQualify.svelte";
    import { ChevronDownOutline } from "flowbite-svelte-icons";

    let loading = false;
    let clearResponsesModal = false;
    let downloading_file = false;

    onMount(async () => {
        const resp = await fetchFiles("qualify");

        files_list.update((prev) => {
            prev["qualify"] = resp;
            return prev;
        });
    });

    const handleSubmit = async () =>
    {
        loading = true;
        console.log("submit");
        toast.push("Request submitted");

        // research - baseline
        let resp = await ask_llm(
            $qualify.research.baseline?.user_instructions,
            "qualify",
            "research",
            "baseline",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["research"]["baseline"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //research - internet search
        resp = await ask_llm(
            $qualify.research.baseline?.generated_content,
            "qualify",
            "research",
            "internet",
            "gemini-1.5-pro",
            false,
        );
        qualify.update((prev) => {
            prev["research"]["internet"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //research - feedback
        resp = await ask_llm(
            $qualify.research.feedback?.user_instructions +
         "\n" +
            $qualify.research.baseline?.generated_content +
                "\n" +
                $qualify.research.internet?.generated_content,
            "qualify",
            "research",
            "feedback",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["research"]["feedback"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //risk factors - risk_factors
        resp = await ask_llm(
            $qualify.risk_factors.risk_factors?.user_instructions,
            "qualify",
            "risk_factors",
            "risk_factors",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["risk_factors"]["risk_factors"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //comprehensiveness - baseline
        resp = await ask_llm(
            $qualify.comprehensiveness.baseline?.user_instructions,
            "qualify",
            "comprehensiveness",
            "baseline",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["comprehensiveness"]["baseline"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //comprehensiveness - advance
        resp = await ask_llm(
            $qualify.comprehensiveness.advance?.user_instructions,
            "qualify",
            "comprehensiveness",
            "advance",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["comprehensiveness"]["advance"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //comprehensiveness - uncertainty
        resp = await ask_llm(
            $qualify.comprehensiveness.uncertainty?.user_instructions,
            "qualify",
            "comprehensiveness",
            "uncertainty",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["comprehensiveness"]["uncertainty"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //comprehensiveness - resolution
        resp = await ask_llm(
            $qualify.comprehensiveness.resolution?.user_instructions,
            "qualify",
            "comprehensiveness",
            "resolution",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["comprehensiveness"]["resolution"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //questions - research
        resp = await ask_llm(
            $qualify.questions.research?.user_instructions +
                "\nBaseline Statements: " +
                $qualify.research.baseline?.generated_content +
                "\nInternet Search Asssessment: " +
                $qualify.research.internet?.generated_content +
                "Research Feedback:\n" +
                $qualify.research.feedback?.generated_content,
            "qualify",
            "questions",
            "research",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["questions"]["research"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //questions - risk factors
        resp = await ask_llm(
            $qualify.questions.risk_factors?.user_instructions +
                "\nRisk Factors: " +
                $qualify.risk_factors.risk_factors?.generated_content,
            "qualify",
            "questions",
            "risk_factors",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["questions"]["risk_factors"]["generated_content"] =
                resp.generated_content;
            return prev;
        });

        //questions - comprehensiveness
        resp = await ask_llm(
            $qualify.questions.comprehensiveness?.user_instructions +
                "\nBaseline Statements: " +
                $qualify.comprehensiveness.baseline?.generated_content +
                "\nAdvance Statements: " +
                $qualify.comprehensiveness.advance?.generated_content +
                "\nUncertainty Statements: " +
                $qualify.comprehensiveness.uncertainty?.generated_content +
                "\nResolution Statements: " +
                $qualify.comprehensiveness.resolution?.generated_content,
            "qualify",
            "questions",
            "comprehensiveness",
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev["questions"]["comprehensiveness"]["generated_content"] =
                resp.generated_content;
            return prev;
        });
        toast.push("Qualify responses completed");
        loading = false;
    };

    async function handleDownload(type) {
        downloading_file = true;
        let payload = {};
        Object.keys($qualify).forEach(key => {
            Object.keys($qualify[key]).forEach(sub_key => {
                payload[key + "_" + sub_key] = $qualify[key][sub_key].generated_content;
            });
        });
        let resp = await download_file(
            payload,
            type,
            "qualify",
            "Qualify",
        );
        downloading_file = false;
    }

    $: submit_disabled = $files_list.qualify.length == 0 || $files_list.qualify.some(file => file.status.toLowerCase() !== 'ready');
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <span class="text-xs text-purple-500">(Beta)</span>
            <FilesTable module="qualify"></FilesTable>
            <div class="flex justify-end my-5">
                {#if loading}
                    <div class="flex items-center">
                        <Spinner />
                    </div>
                {:else}
                    <Button size="sm" color="purple" on:click={handleSubmit} bind:disabled={submit_disabled}
                        >Submit</Button
                    >
                {/if}
            </div>

            <P size="lg" class="my-5"><Span gradient>Baseline Research</Span></P
            >

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <ResponseComponentQualify
                    title="Baseline Statements"
                    formattedContent={$qualify.research.baseline?.generated_content}
                    module="qualify"
                    section="research"
                    sub_section="baseline"
                ></ResponseComponentQualify>

                <ResponseComponentQualify
                    title="Internet Search"
                    formattedContent={$qualify.research.internet?.generated_content}
                    module="qualify"
                    section="research"
                    sub_section="internet"
                ></ResponseComponentQualify>
                <ResponseComponentQualify
                    title="Feedback"
                    formattedContent={$qualify.research.feedback?.generated_content}
                    module="qualify"
                    section="research"
                    sub_section="feedback"
                    injected_extra_prompt={$qualify.research.baseline?.generated_content}
                ></ResponseComponentQualify>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Risk Factors</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <ResponseComponentQualify
                    title="Risk Factors"
                    formattedContent={$qualify.risk_factors.risk_factors?.generated_content}
                    module="qualify"
                    section="risk_factors"
                    sub_section="risk_factors"
                    injected_extra_prompt={$qualify.research.feedback?.generated_content}
                ></ResponseComponentQualify>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Comprehensiveness</Span></P
            >

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <ResponseComponentQualify
                    title="Baseline"
                    formattedContent={$qualify.comprehensiveness.baseline?.generated_content}
                    module="qualify"
                    section="comprehensiveness"
                    sub_section="baseline"
                ></ResponseComponentQualify>

                <ResponseComponentQualify
                    title="Advance"
                    formattedContent={$qualify.comprehensiveness.advance?.generated_content}
                    module="qualify"
                    section="comprehensiveness"
                    sub_section="advance"
                ></ResponseComponentQualify>

                <ResponseComponentQualify
                    title="Uncertainty"
                    formattedContent={$qualify.comprehensiveness.uncertainty?.generated_content}
                    module="qualify"
                    section="comprehensiveness"
                    sub_section="uncertainty"
                ></ResponseComponentQualify>

                <ResponseComponentQualify
                    title="Resolution"  
                    formattedContent={$qualify.comprehensiveness.resolution?.generated_content} 
                    module="qualify"
                    section="comprehensiveness"
                    sub_section="resolution"
                ></ResponseComponentQualify>
            </Accordion>

            <P size="lg" class="my-5"
                ><Span gradient>Questions for Client</Span></P
            >

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <ResponseComponentQualify
                    title="Research"
                    formattedContent={$qualify.questions.research?.generated_content}
                    module="qualify"
                    section="questions"
                    sub_section="research"
                    injected_extra_prompt={$qualify.comprehensiveness.baseline?.generated_content + "\n" + $qualify.research.internet?.generated_content + "\n" + $qualify.research.feedback?.generated_content}
                ></ResponseComponentQualify>

                <ResponseComponentQualify
                    title="Risk Factors"
                    formattedContent={$qualify.questions.risk_factors?.generated_content}
                    module="qualify"
                    section="questions"
                    sub_section="risk_factors"
                    injected_extra_prompt={$qualify.risk_factors.risk_factors?.generated_content}
                ></ResponseComponentQualify>

                <ResponseComponentQualify
                    title="Comprehensiveness"
                    formattedContent={$qualify.questions.comprehensiveness?.generated_content}
                    module="qualify"
                    section="questions"
                    sub_section="comprehensiveness"
                    injected_extra_prompt={$qualify.comprehensiveness.baseline?.generated_content + "\n" + $qualify.comprehensiveness.advance?.generated_content + "\n" + $qualify.comprehensiveness.uncertainty?.generated_content + "\n" + $qualify.comprehensiveness.resolution?.generated_content}
                ></ResponseComponentQualify>
            </Accordion>
            

            <div class="flex justify-end my-10">
                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >

                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text = "";
                                Object.keys($qualify).forEach(key => {
                                    Object.keys($qualify[key]).forEach(sub_key => {
                                        text += key + "_" + sub_key + ": \n" + $qualify[key][sub_key].generated_content + "\n";
                                    });
                                });
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
        </Card>
    </div>
</div>
<Modal
    title="Clear Generated Responses & Files"
    bind:open={clearResponsesModal}
    autoclose
>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all the generated responses and files?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone. All generated content will be removed.
    </p>
    <svelte:fragment slot="footer">
        <Button
            color="red"
            on:click={async () => {
                console.log("clearing responses");
                qualify.update(prev => qualify_initial);
                await deleteAllFiles("qualify");
                files_list.update((prev) => {
                    prev["qualify"] = [];
                    return prev;
                });
                toast.push("Responses and files cleared");
            }}>Clear Responses</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>


<Modal
    title="Add Instructions"
    bind:open={$qualify_add_instructions_modal_open}
    autoclose
>
    <Textarea rows="5" bind:value={$qualify[$selected_section][$selected_sub_section].user_instructions}
    ></Textarea>
    <div class="flex justify-end">
        <Button on:click={()=> {
            qualify_add_instructions_modal_open.set(false);
        }}>Save</Button>
    </div>
</Modal>
