<script>
  import { onMount } from "svelte";
  import { fade, slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  import { customerSupport } from "../service/http_calls";

  // State variables
  let isOpen = false;
  let category = "";
  let sub_category = "";
  let message = "";
  let submitted = false;

  // Categories and Subcategories
  const categories = {
    "Technical Issue": ["Drafting", "Review", "Qualify", "Research", "Other"],
    Billing: ["Invoice Inquiry", "Refund Request", "Payment Method", "Other"],
    "General Inquiry": ["Product Information", "Feedback", "Other"],
  };

  const subCategories = () => categories[category] || [];

  // Handle form submission
  async function handleSubmit(event) {
    event.preventDefault();
    // Here you can handle the form submission, e.g., send data to your backend
    console.log({
      category,
      sub_category,
      message,
    });

    //   TODO: call the endpoint here

    await customerSupport({
      category,
      sub_category,
      message,
    });

    submitted = true;
    // Reset form fields after submission
    category = "";
    sub_category = "";
    message = "";
  }

  // Close modal and reset submission state
  function closeModal() {
    isOpen = false;
    submitted = false;
  }
</script>

<!-- Floating Button -->
<button class="floating-button" on:click={() => (isOpen = true)}> 💬 </button>

{#if isOpen}
  <div class="modal-overlay" transition:fade>
    <div class="modal" transition:slide>
      <button class="close-button" on:click={closeModal}>×</button>
      {#if !submitted}
        <h2>Customer Support</h2>
        <form on:submit|preventDefault={handleSubmit}>
          <label for="category">Category:</label>
          <select id="category" bind:value={category} required>
            <option value="" disabled selected>Select a category</option>
            {#each Object.keys(categories) as cat}
              <option value={cat}>{cat}</option>
            {/each}
          </select>

          {#if category}
            <label for="sub_category">Sub Category:</label>
            <select id="sub_category" bind:value={sub_category} required>
              <option value="" disabled selected>Select a Sub Category</option>
              {#each subCategories() as subCat}
                <option value={subCat}>{subCat}</option>
              {/each}
            </select>
          {/if}

          <label for="message">Message:</label>
          <textarea id="message" bind:value={message} required></textarea>

          <button type="submit" class="submit-button">Submit</button>
        </form>
      {:else}
        <div class="confirmation">
          <h3>Thank you!</h3>
          <p>
            We have received your message and will get back to you as soon as
            possible.
          </p>
          <button class="submit-button" on:click={closeModal}>Close</button>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  /* Floating Button Styles */
  .floating-button {
    position: fixed;
    bottom: 90px;
    right: 20px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    z-index: 1000;
  }

  .floating-button:hover {
    background-color: rgb(0, 128, 128);
  }

  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .modal h2 {
    margin-top: 0;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  /* Form Styles */
  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  select,
  textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  textarea {
    resize: vertical;
    min-height: 80px;
  }

  button.submit-button {
    margin-top: 15px;
    padding: 10px;
    background-color: purple;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  button.submit-button:hover {
    background-color: purple;
  }

  /* Confirmation Message */
  .confirmation {
    text-align: center;
    padding: 20px;
  }

  .confirmation h3 {
    color: #28a745;
  }
</style>
