<script>
    import { marked } from "marked";
    import { AccordionItem, Button, Spinner, Toggle } from "flowbite-svelte";
    import {
        defend,
        defend_instructions_modal_open,
        selected_section,
        selected_sub_section,
    } from "../service/store";
    import { ask_llm, async_ask_llm } from "../service/http_calls";
    import { toast } from "@zerodevx/svelte-toast";
    import { FileCopyOutline, TrashBinOutline } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    export let title;
    export let section;
    export let sub_section;
    let loading_generate_draft = false;
    let generate_draft = async () => {


        if (section == "standard") {
            loading_generate_draft = true;
            console.log("generate_draft");
            let res = await ask_llm(
                $defend?.user_instructions +
                "\n" +
                $defend?.[section]?.[sub_section]?.user_instructions +
                "\n" +
                $defend?.[section]?.["outline"]?.generated_content,
            "defend",
            section,
            "draft",
            "gpt-4o",
            true,
        );
        defend.update((prev) => {
            prev[section]["draft"].generated_content = res.generated_content;
            return prev;
        });
        console.log("res", res);
        loading_generate_draft = false;
    }else{
        loading_generate_draft = true;
            console.log("generate_draft");
            let res = await ask_llm(
                $defend?.freeform?.question +
                "\n" +
                $defend?.user_instructions +
                "\n" +
                $defend?.[section]?.[sub_section]?.user_instructions +
                "\n" +
                $defend?.[section]?.["outline"]?.generated_content,
            "defend",
            section,
            "draft",
            "gpt-4o",
            true,
        );
        defend.update((prev) => {
            prev[section]["draft"].generated_content = res.generated_content;
            return prev;
        });
        console.log("res", res);
        loading_generate_draft = false;
    }
    };
    let edit_mode = false;
    let edit_content = "";
    let textarea;
    function adjustHeight() {
        if (textarea) {
            textarea.style.height = "auto"; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
        }
    }
    onMount(() => {
        edit_content =
            $defend?.[section]?.[sub_section]?.generated_content || "";
        requestAnimationFrame(adjustHeight); // Use requestAnimationFrame for better timing
    });

    let regenerate_outline = async () => {
        defend.update((prev) => {
            prev[section][sub_section]["loading"] = true;
            return prev;
        });
        console.log("regenerate_outline");
        let resp = await async_ask_llm(
            $defend?.user_instructions +
                "\n" +
                $defend?.[section]?.[sub_section]?.user_instructions,
            "defend",
            section,
            "outline",
            "gpt-4o",
            [],
        );
        defend.update((prev) => {
            prev[section]["outline"]["result_id"] = resp.result_id;
            return prev;
        });
    };

    let regenerate_draft = async () => {
        console.log("regenerate_draft");

        defend.update((prev) => {
            prev[section][sub_section]["loading"] = true;
            return prev;
        });
        console.log("regenerate_outline");
        let resp = await async_ask_llm(
            $defend?.user_instructions +
                "\n" +
                $defend?.[section]?.[sub_section]?.user_instructions +
                "\n" +
                $defend?.[section]?.["outline"]?.generated_content,
            "defend",
            section,
            "draft",
            "gpt-4o",
            [],
        );
        defend.update((prev) => {
            prev[section]["draft"]["result_id"] = resp.result_id;
            return prev;
        });
    };
</script>

<AccordionItem>
    <span slot="header" class="font-medium">{title}</span>
    <div class="prose max-w-full">
        {#if $defend?.[section]?.[sub_section]?.generated_content}
            {#if edit_mode}
                <div class="prose max-w-full">
                    <textarea
                        bind:this={textarea}
                        bind:value={edit_content}
                        class="w-full"
                        style="height: auto; min-height: 100px; resize: none;"
                    ></textarea>
                </div>
            {:else}
                {@html marked(
                    $defend?.[section]?.[sub_section]?.generated_content,
                )}
            {/if}
        {:else}
            <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
        {/if}
    </div>

    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    navigator.clipboard.writeText(
                        $defend?.[section]?.[sub_section]?.generated_content,
                    );
                    toast.push("Copied to clipboard!");
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>

            <button
                class="mr-5"
                on:click={() => {
                    defend.update((prev) => {
                        prev[section][sub_section].generated_content = "";
                        return prev;
                    });
                }}
            >
                <TrashBinOutline></TrashBinOutline>
            </button>

            <Toggle
                bind:checked={edit_mode}
                on:change={(e) => {
                    if (!e.target.checked) {
                        // Going from checked to unchecked
                        const hasUnsavedChanges =
                            edit_content !==
                            $defend?.[section]?.[sub_section]
                                ?.generated_content;
                        if (hasUnsavedChanges) {
                            window.addEventListener("beforeunload", (event) => {
                                event.preventDefault();
                                toast.push(
                                    "You have unsaved changes. Please first save your changes.",
                                );
                                event.returnValue = "";
                            });
                            const confirm_exit = confirm(
                                "You have unsaved changes. Are you sure you want to exit edit mode?",
                            );
                            if (!confirm_exit) {
                                edit_mode = true; // Keep toggle checked
                                requestAnimationFrame(adjustHeight);
                                e.preventDefault();
                                return;
                            } else {
                                window.removeEventListener(
                                    "beforeunload",
                                    null,
                                );
                            }
                        }
                    } else {
                        edit_content =
                            $defend?.[section]?.[sub_section]
                                ?.generated_content || "";
                    }

                    requestAnimationFrame(adjustHeight);
                }}
                size="small"
                class="mr-5"
            >
                Edit Mode
            </Toggle>

            {#if edit_mode}
                <Button
                    size="xs"
                    color="alternative"
                    on:click={() => {
                        $defend[section][sub_section].generated_content =
                            edit_content;
                        edit_mode = false;
                        toast.push("Changes saved");
                    }}
                >
                    Save Changes
                </Button>
            {/if}
        </div>

        <div class="flex justify-end">
            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    defend_instructions_modal_open.set(true);
                    selected_section.set(section);
                    selected_sub_section.set(sub_section);
                }}>Add Instructions</Button
            >

            {#if sub_section == "outline"}
                {#if $defend?.[section]?.[sub_section].loading}
                    <Spinner class="mr-5"></Spinner>
                {:else}
                    <Button
                        size="xs"
                        color="purple"
                        class="mr-5"
                        on:click={() => {
                            regenerate_outline();
                        }}>Regenerate</Button
                    >
                {/if}
            {:else if sub_section === "draft"}
                {#if $defend?.[section]?.[sub_section].loading}
                    <Spinner></Spinner>
                {:else}
                    <Button
                        size="xs"
                        color="purple"
                        on:click={() => {
                            regenerate_draft();
                        }}>Regenerate</Button
                    >
                {/if}
            {/if}
            {#if sub_section === "outline"}
                {#if loading_generate_draft}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button
                        size="xs"
                        color="purple"
                        on:click={() => {
                            generate_draft();
                        }}>Generate Draft</Button
                    >
                {/if}
            {/if}
        </div>
    </div>
</AccordionItem>
