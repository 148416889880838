<script lang="ts">import { Section, Quotes, Testimonial, TestimonialCard, TestimonialCardItem } from "flowbite-svelte-blocks";
</script>

<Section name="testimonial">
  <Testimonial icon={Quotes}>
    <blockquote>
      <p class="text-2xl font-medium text-gray-900 dark:text-white">"It lets me do 10 times as much work... it takes away the leg work, which also makes it more enjoyable."</p>
    </blockquote>
    <svelte:fragment slot="footer">
      <!-- <img class="w-6 h-6 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png" alt="michael profile" /> -->
      <!-- <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
        <div class="pr-3 font-medium text-gray-900 dark:text-white">Micheal Gough</div>
        <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">CEO at Google</div>
      </div> -->
    </svelte:fragment>
  </Testimonial>
</Section>