<script>
    import {
        AccordionItem,
        Accordion,
        Button,
        P,
        Span,
        Modal,
        Textarea,
    } from "flowbite-svelte";
    import {
        question_prompt_edit_open,
        questions,
        selected_question,
    } from "../service/store";
    import SingleQuestion from "./SingleQuestion.svelte";
</script>

<P size="lg" class="my-5"><Span gradient>Draft</Span></P>
<Accordion
    multiple
    activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
    inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
>
    {#each Object.entries($questions) as [question_key, question_val]}
        <SingleQuestion question={question_key} title={question_val.title} hideAdjustment={question_key==="q_1"}
        ></SingleQuestion>
    {/each}
</Accordion>

<Modal title="Add Instructions" bind:open={$question_prompt_edit_open} autoclose>
    <P size="lg" weight="bold">{$questions[$selected_question].title}</P>
    <Textarea rows="5" bind:value={$questions[$selected_question].prompt}
    ></Textarea>
    <div class="flex justify-end">
        <Button on:click={()=> {
            question_prompt_edit_open.set(false);
        }}>Save</Button>
    </div>
</Modal>
