<script>
  import {
    defend,
    defend_submit_loading,
    loading_compile,
    loading_defend,
    loading_review,
  } from "./service/store.js";
  import { SvelteToast, toast } from "@zerodevx/svelte-toast";
  import { onMount } from "svelte";
  import Drafting from "./lib/Drafting.svelte";
  import EarlyAccess from "./lib/EarlyAccess.svelte";
  import Footer from "./lib/Footer.svelte";
  import Nav from "./lib/Nav.svelte";
  import Profile from "./lib/Profile.svelte";
  import Top from "./lib/Top.svelte";
  import Research from "./lib/Research.svelte";
  import Learn from "./lib/Learn.svelte";
  import Qualify from "./lib/Qualify.svelte";
  import Review from "./lib/Review.svelte";
  import Collaborate from "./lib/Collaborate.svelte";
  import Defend from "./lib/Defend.svelte";
  import Subscriptions from "./lib/Subscriptions.svelte";
  import Success from "./lib/Success.svelte";
  import Terms from "./lib/Terms.svelte";

  import {
    authenticated_user,
    current_user,
    files_list,
    questions,
    result_informed,
    review,
    terminal_messages,
  } from "./service/store";
  import SupportChat from "./lib/SupportChat.svelte";
  import {
    check_if_all_done,
    fetchFiles,
    get_result,
    getUser,
  } from "./service/http_calls";
  import { P, Spinner, Card } from "flowbite-svelte";
  import GrantQualify from "./lib/GrantQualify.svelte";
  import GrantDrafting from "./lib/GrantDrafting.svelte";
  import GrantReview from "./lib/GrantReview.svelte";
  import LoginComponent from "./lib/LoginComponent.svelte";
  import SignUpComponent from "./lib/SignUpComponent.svelte";
  import Chat from "./lib/Chat.svelte";
  import CookieConsent from "./lib/CookieConsenter.svelte";
  import Features from "./lib/Features.svelte";
  import Testimonials from "./lib/Testimonials.svelte";
  import ContactForm from "./lib/ContactForm.svelte";
  let slug = "";

  let socket;
  const WS_BASE_URL = import.meta.env.VITE_WS_BASE_URL;
  let isOpen = false;
  let page_loading = false;
  const sections = ["top", "features", "testimonials", "pricing", "contact"];
  let currentIndex = 0;

  function scrollToSection(index) {
    document
      .getElementById(sections[index])
      .scrollIntoView({ behavior: "smooth" });
    currentIndex = index; // Update the index to prevent double scrolling
  }

  // Initialize WebSocket connection
  function connectWebSocket() {
    const url = `${WS_BASE_URL}/ws`;
    console.log(`Attempting to connect to ${url}`);
    socket = new WebSocket(url);

    socket.onopen = () => {
      console.log("Connected to WebSocket");
      isOpen = true;
    };

    socket.onmessage = async (event) => {
      let parsed_payload = JSON.parse(event.data);

      if (parsed_payload.type === "error") {
        toast.push(parsed_payload.message, { classes: ["warn"] });
      }

      if (parsed_payload.type != "command") {
        terminal_messages.set([...$terminal_messages, parsed_payload]);
      }

      if (parsed_payload.type === "command") {
        if (parsed_payload.message.function === "get_files") {
          const resp = await fetchFiles(parsed_payload.message.module);

          let existing_files_list = $files_list[parsed_payload.message.module];
          let new_files_list = [];
          if (Array.isArray(resp)) {
            for (let i = 0; i < existing_files_list.length; i++) {
              let f = existing_files_list[i];
              let exists_in_new = resp.find((obj) => obj.name === f.name);
              if (typeof exists_in_new !== "undefined") {
                new_files_list.push(exists_in_new);
              } else {
                new_files_list.push(f);
              }
            }
          }

          files_list.update((prev) => {
            prev[parsed_payload.message.module] = new_files_list;
            return prev;
          });
          console.log("Got files for " + parsed_payload.message.module);
        } else if (parsed_payload.message.function === "get_result") {
          if (parsed_payload.message.module == "draft") {
            console.log("module is draft, payload is ", parsed_payload);
            const question = parsed_payload.message.question;
            const result_id = parsed_payload.message.result_id;

            let res = await get_result(result_id);
            console.log("Got back result for result_id", result_id, res);

            if (res.status === "done") {
              if ($result_informed[res.id] != true) {
                toast.push(`Question ${question} done`);
              }
              result_informed.update((prev) => {
                prev[res.id] = true;
                return prev;
              });
            }
            questions.update((prev) => {
              prev[question].response = res;
              return prev;
            });




            const all_result_ids = Object.values($questions)
                .filter(question => question.result_id !== undefined && question.result_id !== "")
                .map(question => question.result_id);

            console.log("all_result_ids", all_result_ids.length);
            const all_done_resp = await check_if_all_done(all_result_ids);
            console.log("all_done_resp", all_done_resp);
            if (all_done_resp.count_done === all_result_ids.length) {
                toast.push("Draft Completed");
                loading_compile.set(false);
            }



          } else if (parsed_payload.message.module == "review") {
            const section = parsed_payload.message.section;
            const sub_section = parsed_payload.message.sub_section;
            const result_id = parsed_payload.message.result_id;

            let res = await get_result(result_id);

            if (res.status === "done") {
              console.log("review done for ", section, sub_section);
              review.update((prev) => {
                prev[section][sub_section].response = res.content;
                return prev;
              });

              const all_result_ids = Object.values($review).flatMap((section) =>
                Object.values(section)
                  .filter(
                    (sub_section) =>
                      sub_section.result_id !== undefined &&
                      sub_section.result_id !== "",
                  )
                  .map((sub_section) => sub_section.result_id),
              );
              console.log("all_result_ids", all_result_ids.length);
              const all_done_resp = await check_if_all_done(all_result_ids);
              console.log("all_done_resp", all_done_resp);
              if (all_done_resp.count_done === all_result_ids.length) {
                toast.push("Review done");
                loading_review.set(false);
              }
            }
          } else if (
            parsed_payload.message.module == "defend"
          ) {
            console.log("module is defend, payload is ", parsed_payload);
            const section = parsed_payload.message.section;
            const sub_section = parsed_payload.message.sub_section;
            const result_id = parsed_payload.message.result_id;

            let res = await get_result(result_id);

            if (res.status === "done") {
              console.log("defend done for ", section, sub_section);


              defend.update((prev) => {
                prev[section][sub_section].generated_content = res.content;
                prev[section][sub_section].loading = false;
                return prev;
              });

              const all_result_ids = Object.values($defend).flatMap((section) =>
                Object.values(section)
                  .filter(
                    (sub_section) =>
                      sub_section.result_id !== undefined &&
                      sub_section.result_id !== "",
                  )
                  .map((sub_section) => sub_section.result_id),
              );

              console.log("all_result_ids", all_result_ids.length);
              const all_done_resp = await check_if_all_done(all_result_ids);
              console.log("all_done_resp", all_done_resp);
              if (all_done_resp.count_done === all_result_ids.length) {
                toast.push("Defend Completed");
                defend_submit_loading.set(false);
                loading_defend.set(false);
              }
            }
          } 
        }
      } else if (parsed_payload.type === "notification") {
        toast.push(parsed_payload.message);
      }
    };

    socket.onclose = async () => {
      console.log("Disconnected from WebSocket");
      isOpen = false;
      page_loading = false;
      toast.push("Websocket disconnected, please refresh the page!");
      await authenticate();

      // sessionStorage.setItem("isOpen", "false"); // Mark as closed
    };

    socket.onerror = async (error) => {
      console.error("WebSocket error:", error);
      // toast.push("Websocket error, please refresh the page!");
      page_loading = false;
    };
  }
  onMount(async () => {
    if (!localStorage.getItem("isCleared")) {
      localStorage.clear();
      localStorage.setItem("isCleared", "true");
    }

    // Extracting the slug from the URL
    const pathParts = window.location.pathname.split("/").filter(Boolean);
    if (pathParts.length > 0) {
      slug = pathParts[pathParts.length - 1];
      console.log("You are at " + slug);
    } else {
      console.log("You are home page!");
    }

    // if ($current_user) {
    //   userId = $current_user.id;
    // } else {
    if (
      slug != "login" &&
      slug != "signup" &&
      slug != "early-access" &&
      slug != "terms" &&
      slug != ""
    ) {
      console.log("will try to authenticate...");
      page_loading = true;
      await authenticate();
    }
    // }
    if (!isOpen && $current_user) {
      connectWebSocket();
    }

    function handleScroll(event) {
      if (event.deltaY > 0 && currentIndex < sections.length - 1) {
        // Scroll down
        currentIndex++;
        scrollToSection(currentIndex);
      } else if (event.deltaY < 0 && currentIndex > 0) {
        // Scroll up
        currentIndex--;
        scrollToSection(currentIndex);
      }
    }

    // window.addEventListener("wheel", handleScroll, { passive: true });

    // return () => {
    //   window.removeEventListener("wheel", handleScroll);
    // };
  });

  async function authenticate() {
    current_user.set(null);
    let auth_resp = await getUser();
    page_loading = false;

    console.log(auth_resp.id);
    if (typeof auth_resp?.id === "undefined") {
      console.log("should redirect right?");
      if (
        slug != "login" &&
        slug != "signup" &&
        slug != "terms" &&
        slug != ""
      ) {
        window.location.href = "/login";
      } else {
        current_user.set(null);
        authenticated_user.set(null);
      }
    } else {
      current_user.set(auth_resp);
      authenticated_user.set(auth_resp);
    }
  }
</script>

<svelte:head>
  <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
</svelte:head>

<Nav></Nav>

{#if page_loading}
  <div class="flex justify-center items-center mt-10">
    <div class="flex flex-col items-center">
      <Spinner class="my-10"></Spinner>
      <P weight="bold">Loading, please wait...</P>
    </div>
  </div>
{:else if slug === ""}
  <div class="container mx-auto overflow-hidden mx-40">
    <section id="top" class="flex items-center justify-center my-40 ml-36">
      <Top />
    </section>

    <section
      id="features"
      class="w-full flex items-center justify-center my-40"
    >
      <div class="w-full border border-gray-200 rounded-lg p-10">
        <Features />
      </div>
    </section>

    <section
      id="testimonials"
      class="max-w-full flex items-center justify-center my-40"
    >
      <div class="w-full border border-gray-200 rounded-lg p-10">
        <Testimonials />
      </div>
    </section>

    <section
      id="pricing"
      class="max-w-full flex items-center justify-center my-40"
    >
      <div class="w-full border border-gray-200 rounded-lg p-10">
        <stripe-pricing-table
          pricing-table-id="prctbl_1QCfaGAnHQme9GY61y9EgGJP"
          publishable-key="pk_live_51PyTlIAnHQme9GY6dje7mRSTC1tXhUSjX8lfDBc3XpBQ26AKUw6lezjc4P7VOJJMkE0SP4wnRGSu08TEmycdPEms002yaz9jbz"
        />
      </div>
    </section>

    <section
      id="contact"
      class="w-full flex flex-col items-center justify-center my-40"
    >
      <div class="w-full border border-gray-200 rounded-lg p-10">
        <ContactForm />
      </div>
    </section>
  </div>
{:else if slug == "drafting"}
  <Drafting></Drafting>
{:else if slug == "early-access"}
  <EarlyAccess></EarlyAccess>
{:else if slug == "profile"}
  <Profile></Profile>
{:else if slug == "research"}
  <Research></Research>
{:else if slug == "learn"}
  <Learn></Learn>
{:else if slug == "qualify"}
  <Qualify></Qualify>
{:else if slug == "review"}
  <Review></Review>
{:else if slug == "collaborate"}
  <Collaborate></Collaborate>
{:else if slug == "defend"}
  <Defend></Defend>
{:else if slug == "subscriptions"}
  <Subscriptions></Subscriptions>
{:else if slug == "success"}
  <Success></Success>
{:else if slug == "grant-qualify"}
  <GrantQualify></GrantQualify>
{:else if slug == "grant-draft"}
  <GrantDrafting></GrantDrafting>
{:else if slug == "grant-review"}
  <GrantReview></GrantReview>
{:else if slug == "login"}
  <LoginComponent></LoginComponent>
{:else if slug == "signup"}
  <SignUpComponent></SignUpComponent>
{:else if slug == "terms"}
  <div class="flex justify-center items-center mt-10">
    <Card size="xl" class="m-40 p-10">
      <Terms />
    </Card>
  </div>
{:else if slug == "chat"}
  <Chat />
{/if}

<SvelteToast options={{ classes: ["log"], duration: 8000 }} />

{#if $current_user && slug != "" && typeof $authenticated_user?.id !== "undefined"}
  <SupportChat />
{/if}

<CookieConsent
  allowButtonText={"Accept"}
  closeText={"Close"}
  descriptionText={"We are using cookies to improve your experience  "}
  cookiePolicyText={"Learn More"}
  cookiePolicyUrl={"https://smartclaim.uk/terms"}
  bgColor={"bg-gray-200"}
  buttonTextColor={"text-white"}
  closeTextColor={"text-purple-500"}
  buttonBgColor={"bg-purple-500"}
  descriptionTextColor={"text-gray-600"}
/>

<Footer></Footer>

<style>
  :global(.log.info) {
    --toastBackground: green;
  }
  :global(.log.warn) {
    --toastBackground: red;
  }
</style>
