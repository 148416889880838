<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { fetchFiles, getUser } from "../service/http_calls";
    import {
        files_list,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import Speedometer from "svelte-speedometer";
    import { marked } from "marked";
    let options = {
        thickness: 12,
        legend: "Total",
        color: "orangered",
        bgColor: "#bbb",
        valueOverflow: true,
        textColor: "#ffc0b5",
    };
    onMount(async () => {
        const resp = await fetchFiles("qualify");

        files_list.update( (prev) => {
            prev["qualify"] = resp;
            return prev;
        });

    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <FilesTable module="qualify"></FilesTable>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple">Submit</Button>
            </div>

            <P size="lg" class="my-5"
                ><Span gradient>Qualify</Span></P
            >

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Eligibility</span>
                    <div class="max-w-full prose">
                        {@html marked(`
### **Conditions Satisfied:**

1. **SME Eligibility:**  
   - The project involves SmartClaim, which appears to be designed for R&D advisory and involves SMEs or consultancy teams that focus on SMEs.

2. **UK-Based Operations:**  
   - SmartClaim operates in the UK, and all their claims and clients seem to be based in the UK, which fits the requirement of carrying out all project work in the UK.

3. **Grant Exploitation in the UK:**  
   - The company aims to supercharge UK-based R&D advisory using AI, aligning with the grant's scope to exploit results from or within the UK.

4. **Lead Organisation:**  
   - SmartClaim fits the profile of a UK-registered SME leading the project.

5. **Collaboration Potential:**  
   - The project mentions collaboration, as SmartClaim seems to work with other R&D consultants or teams, potentially indicating the possibility of a collaborative grant project.

6. **Duration of Project:**  
   - SmartClaim’s product roadmap indicates long-term developments, and their intention to launch in 2025 suggests that the project could start before April 2025 and meet the grant duration requirement.

---

### **Conditions Unclear or Missing:**

1. **Project Start and End Dates:**  
   - The document does not explicitly mention a project start date or end date that matches the requirement of starting by 01 April 2025 and ending by 31 March 2027.

2. **Total Eligible Project Costs:**  
   - There is no mention of the estimated project costs, and it's unclear whether the total eligible project costs fall between £100,000 and £1 million, as required by the grant.

3. **Project Duration Rules:**  
   - Although SmartClaim seems to have a roadmap stretching into 2025, the specific duration of the project (6-18 months or 19-24 months) and whether it follows the correct rules based on this duration are not clear.

4. **Collaborative Requirement (if applicable for 19-24 months duration):**  
   - While the info pack suggests potential collaborations, it is not clear if there is a formal partnership with other businesses or academic institutions that would meet the collaborative grant criteria.

5. **Subcontractor and Sanction Clauses:**  
   - There is no mention of any subcontractors involved or specific reference to the grant's requirement to avoid UK sanctions.

6. **Eligible Costs Breakdown:**  
   - The document does not provide details about the specific costs associated with the project or whether they meet the eligibility criteria for Innovate UK funding.

---

### **Actionable Next Steps for Discussion with the SME:**

1. Clarify the exact start and end dates of the project.
2. Provide a breakdown of the total eligible project costs.
3. Confirm if there will be any collaborative partners and ensure these align with Innovate UK requirements.
4. Ensure any subcontractors are UK-based and meet all necessary conditions.
5. Make sure the cost structure adheres to Innovate UK's funding rules, particularly the proportions of eligible costs.

These clarifications will help ensure that the application meets the eligibility criteria for Innovate UK Smart grants.

`)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Strength</span>

                    <div class="max-w-full prose">
                        {@html marked(`
### Overall Assessment: **Medium**

### Rationale:

- **Groundbreaking and Innovative Idea:**
  - The project is focused on using AI to enhance R&D advisory services, which is a promising application of AI in a traditionally human-centered field. However, it is unclear if the approach is **significantly different from already available services** globally or if it represents a **game-changing innovation**.

- **Rapid Commercialisation Potential:**
  - The business case outlines strong potential for efficiency gains (e.g., saving 1 day per claim, 400 days saved annually), which could support rapid commercialisation. However, the document does not sufficiently explain how these gains translate to **scaling** the business rapidly beyond the existing market.

- **Economic Impact and Growth:**
  - The potential for 100% revenue growth is promising, but there is insufficient detail on how this growth will be sustained after the project ends. The project could generate **positive economic impact** but lacks clear evidence of its **global competitiveness** or how it will make the UK a leader in AI-driven R&D consultancy.

- **Clear Business Plan:**
  - While the document includes a solid business case and a pricing model, the **specific financial plan** for the project and post-project activities, particularly around return on investment (ROI) and market entry strategy, is not detailed. The plan for commercialisation is also vague beyond improving internal efficiency.

- **Team Expertise:**
  - The team appears to have strong technical and R&D claim expertise (e.g., AI products for major clients, experience in R&D claims). However, the **business development and commercialisation expertise** needed to drive market growth post-project is less evident.

- **Risk Management:**
  - There is no explicit mention of a **risk management plan** in the provided document, which is a critical aspect the grant looks for, especially in terms of mitigating project and market risks.

- **Fit with the Grant Scheme:**
  - The project does fit Innovate UK’s goals of supporting SMEs with innovative R&D, but **Smart funding** may not be the best option unless the proposal can clearly demonstrate why this is the right funding route compared to other Innovate UK options. The current document does not fully explain **why** Smart funding is essential for this project.

- **Customer and User Focus:**
  - The pricing model and business case suggest a focus on R&D consultants and their clients, but the document lacks clarity on how **customer needs are incorporated** into the solution development. More information on **market research** and customer feedback would strengthen this aspect.

- **Financial and Timeline Plans:**
  - The document does not provide detailed **financial plans** or timelines for project completion, nor does it demonstrate good value for money in terms of how the funds will be allocated to project activities.

---

### Areas for Improvement:

1. Provide **clearer differentiation** of the product from existing AI solutions in the market to demonstrate game-changing innovation.
2. Expand on the **financial and commercialisation plans**, particularly around ROI, market entry strategy, and scaling beyond current clients.
3. Include a detailed **risk management** plan to address potential project challenges.
4. Clarify how **Smart funding** specifically aligns with the project’s goals compared to other potential funding sources.
5. Provide more information on how the project addresses **customer and user needs**, backed by market research and customer feedback.

By addressing these areas, the project could move towards a stronger overall assessment.

  `)}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header">Comprehensiveness</span>
                    <div class="max-w-full prose">
                        {@html marked(`
### **Question 4: Your idea and innovation**

**Missing Information:**
- How your innovation is clearly different from alternative solutions that are already available.
- Whether the innovation is for new solutions in existing areas or a totally new approach or idea.
- Specific need or challenge you propose to address and who would benefit from it, both immediately and in the longer term.
- Any work already done to respond to this need, for example, whether the project focuses on developing an existing capability or building a new one.
- Any challenges or opportunities relating to equality, diversity, and inclusion arising from the project.

---

### **Question 5: Justification for funding**

**Missing Information:**
- The journey you have followed to ensure you are ready for Smart grant funding, including any training, support, and mentoring undertaken.
- Any other routes of investment you have explored (both public and private sectors), why they were unsuitable, or why your application was turned down.
- Why Smart funding is the right route for your project and organisation.
- What will happen to your project if you do not secure Smart funding.
- What clear advantages public funding offers your project that other routes would not (e.g., enabling partnerships, leveraging investment, reducing risk, accelerating routes to market).

---

### **Question 6: The potential market**

**Missing Information:**
- The size and current structure of the specific markets you are targeting (international, domestic, or both), supported by market data.
- Structure and dynamics of the target markets, including competitors, trends affecting growth, and predicted growth rates with clear timeframes.
- Evidence of who is ready to commit to buying your innovation, including any end-user or market research conducted.
- Agreed plan for exploitation, route to market, and commercialisation strategy (e.g., business models, partnerships).
- Export potential for your project outputs.
- Any barriers to market entry and how you will overcome them.
- Freedom to operate and the potential for significant returns.

---

### **Question 7: Impact and benefits**

**Missing Information:**
- How your project fits with current product/service lines or offerings and how your organisation will profit (e.g., revenue increase, cost reduction).
- The timescale needed to realise benefits.
- How the innovation will impact job creation or safeguarding, both within your consortium and the wider sector.
- How you will protect and exploit project outputs (e.g., patents, designs).
- Long-term sustained impacts on the UK economy, society, and environment.
- Expected regional impacts.
- Any negative impacts identified and mitigation plans.
- Consideration of equality, diversity, and inclusion in project development.

---

### **Question 8: Delivering your project**

**Missing Information:**
- Specific details on the diversity of the project team relevant to the approach.
- Details of any roles you will need to recruit for and how equality, diversity, and inclusion will be promoted.
- Resources, equipment, and facilities needed for the project and how you will access them.
- External parties (e.g., subcontractors) that are critical for project success and why project partners cannot complete these tasks.
- If the project is collaborative, how the relationships between project partners will change due to the project.
- Risk register including identified risks, mitigation strategies, and interdependencies.

---

### **Question 9: Value for money**

**Missing Information:**
- Detailed breakdown of costs aligned with tasks or work packages, and how value for money has been calculated (e.g., quotation comparison).
- How you and any project partners will fund your contributions to the project, ensuring readiness for the project start date.
- Subcontractor costs and how these have been calculated.
- Funding and resource plans for post-project activities, to continue the journey towards commercialisation.
- Any wider support needed (e.g., partnerships, private finance) and how this is critical to project success.

---

By addressing these gaps, the application can be strengthened to align more closely with Innovate UK’s expectations for Smart funding.

   `)}
                    </div>
                </AccordionItem>

            </Accordion>

            <P size="lg" class="my-5"
                ><Span gradient>Outline</Span></P
            >
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header">Your idea and innovation</span>
                    <div class="max-w-full prose">
                        {@html marked(`
### Question 4: **Your Idea and Innovation**
**What is your idea and innovation, and why is it game changing?**

- **Specific innovation proposed**:  
  - SmartClaim leverages AI to significantly enhance the R&D advisory process by automating claim drafting, reviewing, and qualifying. The AI-driven system reduces manual effort, saving up to 1 day per claim and allowing up to 100% revenue growth by increasing the capacity to handle more claims .
  
- **How innovation is different from existing solutions**:  
  - Existing R&D claim management solutions rely heavily on manual inputs and traditional document processing methods. SmartClaim introduces an AI-powered tool that improves efficiency by automating much of the repetitive work. It also incorporates deep learning and generative AI models, setting it apart from alternatives that do not fully exploit AI's capabilities .
  
- **New solution in an existing area vs. a totally new approach**:  
  - SmartClaim is a new approach to an existing area, transforming how R&D claims are managed. By integrating AI into the advisory process, it redefines traditional consultancy models with faster, more accurate claim drafting .
  
- **Specific need/challenge addressed**:  
  - The primary challenge SmartClaim addresses is the time-consuming and manual nature of drafting R&D claims. This is especially significant for consultants who must prepare claims within strict timelines, where errors or inefficiencies can result in lost revenue or penalties. Immediate beneficiaries include R&D consultancies, while long-term beneficiaries include businesses claiming R&D tax credits .
  
- **Work already done**:  
  - A guided pilot program has already been implemented, with daily user check-ins and weekly progress reviews. This testing phase has led to a more robust AI model. The product is in its rollout phase, with improvements based on user feedback .
  
- **Challenges or opportunities relating to equality, diversity, and inclusion (EDI)**:  
  - The tool is designed to support consultancies of varying sizes (from 10 to 400 clients) with different pricing models. This inclusive approach ensures that small and large consultancies alike can access AI-driven improvements to their workflows. Further verification with the SME is needed on specific EDI practices, such as addressing potential bias in AI algorithms .

 `)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Justification for funding</span>
                    <div class="max-w-full prose">
                        {@html marked(`
### Question 5: **Justification for Funding**
**Why is your innovation and proposal suitable for Smart funding, and why do you need public funding to help you succeed?**

- **Journey toward grant readiness**:  
  - SmartClaim has already undertaken a pilot program, demonstrating both technical feasibility and a clear business case. The team consists of seasoned professionals with extensive experience in AI and software engineering, and they have received guidance from industry experts .
  
- **Alternative routes of investment explored**:  
  - So far, private sector funding has been considered, but early-stage venture capitalists and angel investors have deemed the project too niche for broad-scale funding. Verification with the SME is required for any public sector rejections .
  
- **Why Smart funding is the right route**:  
  - Innovate UK Smart funding is well-suited because it supports game-changing, innovative technologies like SmartClaim. The funding will enable the company to further develop AI capabilities and refine its model before entering the market, something that traditional private investment may not support due to higher risk profiles .
  
- **What happens if the project does not secure funding**:  
  - Without Smart funding, the project would face delays, and progress would slow significantly. The potential to onboard new users and expand the AI’s capabilities may be hindered, reducing the company’s competitive edge .
  
- **Advantages of public funding**:  
  - Public funding would de-risk the investment, allowing SmartClaim to focus on refinement and growth without the pressures of immediate commercialization. This funding would accelerate market entry, encourage collaboration with other industry players, and create a stronger foundation for securing further investment .
  
- **Justification for public funding**:  
  - The public funding is justified as the project aims to innovate a critical sector (R&D tax claims), which could contribute to the broader economy by enhancing the efficiency and success rate of R&D tax claim submissions, encouraging more companies to invest in innovation .

`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">The potential market</span>
                    <div class="max-w-full prose">
                        {@html marked(`
### **Question 6: The Potential Market**
**What is your target market and what is your strategy for securing the market opportunity you have identified, including your route to market and commercialisation of project outputs?**

---

1. **Size and Current Structure of Target Markets (Domestic and International)**  
    - **Primary Market (Domestic - UK)**: The initial target market consists of UK-based R&D consultancies that provide tax credit advisory services to businesses. There are approximately 500 R&D consultancy firms of varying sizes, serving tens of thousands of businesses seeking R&D tax credits each year. SmartClaim's pricing tiers (for firms with 10-400 clients) suggest the product is designed for small, medium, and large consultancies alike.
    - **International Market (Future Expansion)**: As R&D tax credits and similar incentives exist in other countries (e.g., US, Canada, and EU nations), the international market offers significant expansion potential. Initially, the focus will be on the UK market, with potential to expand to jurisdictions where similar tax credit regimes exist.
    - *Placeholder for SME Verification*: Confirm market size estimates and any specific plans for international market entry.

2. **Structure and Dynamics of Target Markets**
    - **Customer Segmentation**: The market can be segmented into:
        - **Small consultancies**: Those managing 10-30 clients, requiring efficient solutions to handle their client base with limited staff.  
        - **Medium-sized consultancies**: Serving 50-100 clients, requiring scalability to manage growing client portfolios.  
        - **Large consultancies**: Handling 200-400 clients, requiring advanced AI-driven tools to streamline operations and maintain competitive advantage.
    - **Competitors**: Competitors include traditional R&D tax claim software providers and firms offering manual claim preparation services. SmartClaim differentiates itself by automating much of the process, using AI to draft, review, and qualify claims, making it more efficient than manual or semi-automated competitors.
    - **Market Trends**: The increasing complexity of R&D tax credit applications and the growing demand for accurate and efficient claim preparation are driving market growth. Automation and AI are becoming essential for consultancies to stay competitive and reduce the manual burden on their staff.
    - **Predicted Growth Rates**: The R&D tax credit consultancy market is expected to grow as governments encourage innovation through tax incentives. UK R&D tax claims have grown by 10-15% annually over the past decade. With the added value of automation through AI, SmartClaim could capture significant market share within 2-3 years.  
    - *Placeholder for SME Verification*: Verify growth rates and customer segmentation.

3. **Commitment to Buying and ROI**
    - **Early Adopters**: The pilot program has already shown success with key R&D consultants providing feedback and refining the product. These early users demonstrate market readiness and validate demand for SmartClaim. Additional feedback and commitments from pilot users will be used to secure further commercial agreements.
    - **Return on Investment (ROI)**: The projected ROI for customers is significant. By reducing claim preparation time by one day per claim and allowing consultancies to handle up to 400 more clients annually, SmartClaim has the potential to double the capacity of a consultancy, driving substantial revenue growth.
    - *Placeholder for SME Verification*: Confirm any formal commitments or partnerships with early adopters.

4. **Plan for Exploitation, Route to Market, and Commercialisation**
    - **Route to Market**:
        - **Direct Sales**: SmartClaim will be marketed directly to R&D consultancies through targeted outreach, leveraging industry networks such as *The R&D Community*.
        - **Partnerships**: Strategic partnerships with R&D advisory bodies, tax credit associations, and consultancy networks will be key in scaling the business. The SME has already formed connections with design partners experienced in R&D claim preparation.
    - **Commercialisation Strategy**:
        - **Subscription Model**: SmartClaim offers a subscription-based pricing model, making it accessible for consultancies of all sizes. Pricing starts at £79 per month for smaller firms and scales up to £1,599 per month for larger consultancies with custom feature options.
        - **Customer Retention and Upsell**: As consultancies grow their client base, SmartClaim’s tiered pricing will encourage long-term use and scaling within firms. Additional features and advanced AI capabilities will be available as premium offerings.
    - *Placeholder for SME Verification*: Confirm any additional partnerships and business models.

5. **Export Potential**
    - **Global Application**: While initially focused on the UK market, SmartClaim’s AI platform has broad applicability in any country with R&D tax incentive schemes. The platform can be tailored for international markets where R&D tax credits or similar programs exist (e.g., US, Canada, EU).  
    - **Export Readiness**: SmartClaim’s cloud-based architecture and AI model make it easily adaptable for different regulatory frameworks, making it a strong candidate for international expansion within 2-5 years.
    - *Placeholder for SME Verification*: Confirm export plans and timelines.

6. **Barriers to Entry and How They Will Be Overcome**
    - **Market Awareness**: One of the main barriers is establishing brand awareness in a market dominated by traditional claim software providers. SmartClaim will overcome this through targeted marketing campaigns, leveraging industry partnerships, and early success stories from pilot users.
    - **Adoption of AI**: Some consultancies may be slow to adopt AI-driven solutions due to concerns over automation and data security. SmartClaim addresses these concerns by ensuring that its platform operates within strict security guidelines, including data encryption and compliance with GDPR.
    - **Regulatory Hurdles**: As SmartClaim expands internationally, navigating local regulatory environments will be a challenge. The company plans to engage local tax and legal experts in each market to ensure compliance.
    - *Placeholder for SME Verification*: Verify the company’s strategies for overcoming these barriers.

7. **Freedom to Operate and Potential for Significant Returns**
    - **No Existing Patents or Restrictions**: SmartClaim operates in a space where there are no significant patent restrictions on AI-based R&D claim automation. This gives the company freedom to operate and innovate without legal challenges.
    - **Significant Market Returns**: With the UK R&D tax consultancy market estimated at £500 million annually, capturing even 5% of the market would generate substantial returns. SmartClaim’s scalable model ensures that as the market grows, so too does its revenue potential.
    - *Placeholder for SME Verification*: Confirm freedom to operate analysis and refine market return projections.

---

### **Action Items for SME Verification**:
- Confirm market size estimates and any specific plans for international market entry.
- Verify any formal commitments or partnerships with early adopters.
- Clarify partnerships, growth strategies, and customer segments for the route to market and commercialisation plan.
- Confirm export plans, timelines, and freedom to operate status.

---

This outline provides a comprehensive response to the market question while ensuring the necessary verifications are completed with the SME before submission.

`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Impact and benefits</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Here is the structured outline for **Question 7: Impact and Benefits** for the Innovate UK Smart Grant application. Assumptions or placeholders for further verification with the SME are clearly marked.

---

### **Question 7: Impact and Benefits**
**What will be the impact of receiving the grant both for your business and outside your organisation?**

---

1. **Expected Outputs from the Project**  
    - **Service Innovation**: The main output will be the enhanced SmartClaim platform, which incorporates AI-driven tools for automating R&D tax credit claims. This innovation will significantly reduce manual efforts, streamline processes, and improve accuracy【4†source】.  
    - **Additional Features**: Development of advanced features (e.g., deep learning models for claim analysis and automated compliance checks) will be part of the output.  
    - **Supporting Reports**: Regular reports on AI performance, user feedback, and operational impact will be generated throughout the project lifecycle. These will help fine-tune the platform to meet the identified challenges of inefficiency and manual errors in R&D claims【4†source】.
    - *Placeholder for SME Verification*: Confirm specific additional deliverables, such as demonstrators or knowledge outputs.

2. **Fit with Current Offerings and Organisational Benefits**
    - **Enhanced R&D Advisory Services**: The SmartClaim platform aligns with the core business of R&D advisory by offering a fully automated, AI-enhanced service. This innovation will allow the company to expand its offerings from manual or semi-automated R&D claims to a fully automated service【4†source】.  
    - **Increased Revenues and Growth**: The company is projected to double its client-handling capacity, leading to a potential 100% increase in revenue through the efficient handling of up to 400 additional claims annually【4†source】.  
    - **Cost Reduction**: Automation will reduce the labor cost associated with manual claim preparation, allowing consultants to focus on higher-value tasks.
  
3. **Impact on Market Share, Productivity, and Growth (Short- and Long-term)**
    - **Short-term Impact**: Within 12-18 months, the company expects a significant increase in market share, initially focusing on capturing a substantial portion of the UK’s R&D advisory market. Productivity will increase as AI automates claim handling, allowing for faster processing and improved accuracy【4†source】.  
    - **Long-term Impact**: Over 2-5 years, SmartClaim aims to expand internationally, capitalizing on similar tax credit regimes in other countries, leading to sustained growth and enhanced market positioning.
    - **Projected Growth Rates**: *Placeholder for SME Verification*: Verify growth rates for the UK and international markets and refine market impact projections.

4. **Timeline for Realising Benefits**
    - **Year 1**: Completion of advanced AI feature development and initial market launch in the UK. Expected increase in client onboarding and revenue growth.  
    - **Years 2-3**: Expansion into international markets and further refinement of the platform, including additional AI-driven features.  
    - **Years 3-5**: Full commercialisation in multiple regions, further growth in market share, and increased productivity.

5. **Job Creation and Safeguarding**
    - **Within the Organisation**: SmartClaim’s growth will require the creation of new roles in AI development, customer support, and marketing. Additionally, it will safeguard existing jobs by enabling the company to expand its client base without overburdening staff【4†source】.  
    - **Sector-wide Impact**: The platform’s efficiency could reduce the need for manual claim processing roles in other consultancies, though it is expected to create demand for higher-skilled roles in AI integration and oversight.
    - *Placeholder for SME Verification*: Confirm the number of jobs expected to be created or safeguarded.

6. **Protection and Exploitation of Project Outputs**
    - **Intellectual Property Protection**: SmartClaim will focus on protecting its innovations through a combination of proprietary know-how and software design. Patents may not be necessary for the AI algorithms, but the system architecture and data-handling processes could be protected through trade secrets【4†source】.
    - **Business Model Changes**: The platform’s subscription-based pricing model will enable recurring revenue, allowing the company to scale effectively as more consultancies adopt the AI solution【4†source】.
    - *Placeholder for SME Verification*: Verify whether patents or other forms of IP protection are planned.

7. **Sustained Long-term Impacts on the UK Economy, Society, and the Environment**
    - **Economic Impact**: By streamlining the R&D tax credit claim process, SmartClaim will enable more businesses to access these credits, encouraging further R&D investment across the UK. This will have a multiplier effect on the UK economy, fostering innovation and economic growth【4†source】.  
    - **Social Impact**: Automation will reduce the time consultants spend on repetitive tasks, allowing them to focus on providing more strategic advice to clients. This could improve the quality of services across the R&D consultancy sector.
    - **Environmental Impact**: The platform will operate on a cloud-based architecture, minimizing the need for physical documentation and travel, reducing the carbon footprint of R&D claim processes.
    - *Placeholder for SME Verification*: Confirm and expand on regional, social, or environmental impacts.

8. **Regional Impacts**
    - **Direct Regional Impact**: SmartClaim’s expansion could create high-skill jobs in AI and technology sectors across different UK regions, potentially benefiting areas with fewer opportunities for tech-based roles.
    - **Indirect Regional Impact**: The streamlined R&D claim process could increase the uptake of R&D tax credits by regional businesses, fostering innovation across more parts of the UK.
    - *Placeholder for SME Verification*: Verify and elaborate on specific regional impacts.

9. **Negative Impacts and Mitigation Strategies**
    - **Potential Negative Impact**: Automation could displace some jobs in the R&D consultancy space, particularly for lower-skilled roles focused on manual claim processing.
    - **Mitigation**: The platform will focus on augmenting human roles rather than replacing them. By freeing up consultants from manual tasks, the company can offer training and reskilling opportunities, shifting workers into higher-value roles【4†source】.
    - *Placeholder for SME Verification*: Confirm any additional negative impacts and strategies for mitigation.

10. **Equality, Diversity, and Inclusion (EDI) Considerations**
    - **Inclusive Design**: SmartClaim is designed to be accessible to consultancies of all sizes, offering different pricing tiers and features that suit small and large firms alike. This will ensure that smaller consultancies, which may serve more diverse clients, can access the same AI-driven capabilities as larger firms【4†source】.
    - **Unintended Exclusion**: No major risks of excluding minority groups have been identified, but the company will continue to assess its platform’s usability to ensure accessibility for all types of users, including those with disabilities.
    - *Placeholder for SME Verification*: Confirm specific EDI practices and any assessments done on exclusion risks.

---

### **Action Items for SME Verification**:
- Confirm specific deliverables and project outputs (e.g., demonstrators, reports).
- Refine projected job creation or safeguarding numbers.
- Confirm intellectual property protection plans (e.g., patents, trade secrets).
- Verify and expand on regional, social, and environmental impacts.
- Confirm any negative impacts and mitigation strategies.
- Provide further details on EDI considerations.

---

This outline ensures a comprehensive response to the impact and benefits question while leaving room for detailed verification with the SME before submission.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Delivering your project</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Here is the structured outline for **Question 8: Delivering your project** for the Innovate UK Smart Grant application. Assumptions or placeholders for further verification with the SME are clearly marked.

---

### **Question 8: Delivering Your Project**
**Who is in the project team, why do you have the right skills and experience to succeed, and how will you successfully deliver your project?**

---

1. **Team Roles, Skills, Expertise, and Experience**  
    - **Core Team**:  
        - **Shilin Chen (Co-founder & CEO)**: Expertise in building AI products and strategies, having worked with HMRC Compliance and as a former management consultant at McKinsey. Extensive leadership and strategic oversight experience in project delivery【4†source】.  
        - **Amin Dorostanian (Co-founder & CTO)**: 15+ years of software engineering experience, having developed AI products for BMW Group and ABN AMRO Bank. Proven ability to lead complex software development projects, particularly in AI-driven solutions【4†source】.  
    - **Design Partners**:  
        - **Mick (Design Partner)**: ~15 years of experience in R&D claim writing and part of The R&D Community, providing crucial insights into industry-specific claim needs【4†source】.  
        - **Jay (Design Partner)**: ~10 years of experience in R&D claims, ensuring the platform meets real-world demands and integrates industry standards【4†source】.  
    - **Project Management and Risk Management Skills**: The team’s combined experience in large-scale AI product development and consultancy offers strong project management skills, especially for risk management in highly regulated sectors.  
    - **Diversity of Skills**: The team includes a mix of technical, strategic, and industry-specific expertise, ensuring a balanced approach to innovation and commercialisation.  
    - *Placeholder for SME Verification*: Verify if there are any additional team members or specific project management training.

2. **Diversity in the Team and EDI Considerations**
    - The project team is composed of individuals from diverse professional backgrounds, including technical, consultancy, and AI sectors.  
    - **EDI Practices**: The team adheres to the principles of equality, diversity, and inclusion, ensuring that recruitment and project planning foster an inclusive environment. The platform itself is accessible to consultancies of all sizes, promoting diversity within the user base.
    - *Placeholder for SME Verification*: Confirm any additional diversity measures, including gender or ethnic diversity within the team.

3. **Capability to Deliver within the Required Timeframe**
    - **Project Readiness**: The team has already completed a successful pilot phase and demonstrated the product’s feasibility. The project timeline is realistic given the team’s experience in delivering similar AI-driven solutions on time and within budget【4†source】.  
    - **Existing Business Constraints**: There are no known operational constraints that would prevent timely delivery. The company is currently focused on scaling SmartClaim, making this project a priority.  
    - **Commitment to No Delays**: The project plan has been carefully structured to avoid the risk of delays. Clear milestones and responsibilities are assigned to ensure accountability.
    - *Placeholder for SME Verification*: Confirm if any other business activities could impact the project timeline.

4. **Recruitment Needs and Promoting Equality, Diversity, and Inclusion**
    - **Roles to be Recruited**: The company may need to recruit additional AI engineers, customer support staff, and marketing specialists to support platform scaling and user onboarding【4†source】.  
    - **EDI in Recruitment**: The company will follow an inclusive recruitment process, actively seeking to hire individuals from diverse backgrounds. Recruitment channels will promote equality, ensuring the best talent is hired without bias.
    - *Placeholder for SME Verification*: Confirm the specific roles to be recruited and any detailed EDI practices for hiring.

5. **Resources, Equipment, and Facilities**
    - **Cloud-Based Infrastructure**: The project will rely on SmartClaim’s existing cloud infrastructure for platform development and data security. This infrastructure meets industry standards for security and scalability【4†source】.  
    - **AI Development Resources**: The team has access to OpenAI’s API for continued AI model refinement and testing【4†source】.  
    - **Facilities**: Existing office space and remote work setups are sufficient to meet project needs, ensuring flexibility and continuity.
    - *Placeholder for SME Verification*: Confirm if any additional resources or facilities will be required.

6. **External Parties and Subcontractors**
    - **Subcontractors**: The company plans to use subcontractors for specific AI model training tasks and data security audits. These tasks require specialised skills that cannot be met internally【4†source】.  
    - **Rationale for Subcontracting**: Outsourcing these elements will allow the internal team to focus on core development tasks, improving efficiency and risk management.
    - *Placeholder for SME Verification*: Identify specific subcontractors and confirm their roles in the project.

7. **Collaborative Partnerships**
    - **Design Partners**: Collaboration with experienced R&D consultants like Mick and Jay is essential to refining the platform’s features to ensure it meets the real-world needs of consultancies【4†source】.  
    - **Changes in Relationships**: The collaboration will evolve into a long-term partnership for continual platform improvement, with these partners providing feedback and insights post-project.
    - *Placeholder for SME Verification*: Confirm if any other consortium members or partners will be involved.

8. **Project Management Approach**
    - **Tools and Mechanisms**: The project will use agile project management tools, such as Jira or Asana, to track progress and manage tasks across different team members. Regular team check-ins and milestone reviews will ensure the project stays on schedule【4†source】.  
    - **Risk Register**: A detailed risk register will be maintained, tracking risks related to technical development, timelines, and commercialisation. Each risk will have a defined mitigation strategy, such as reallocating resources or adjusting timelines to address technical challenges.  
    - **Progress Tracking**: Weekly sprints and monthly milestone reviews will keep the project on track, with clear accountability across team roles.

9. **Delivering Outcomes and Impact Beyond the Project Life**
    - **Post-Project Commercialisation**: Upon project completion, SmartClaim will continue its commercialisation strategy, including expanding its customer base and scaling to international markets. The subscription-based model will ensure steady revenue growth【4†source】.  
    - **Continued Development**: The team will maintain an ongoing roadmap for platform enhancement, ensuring that SmartClaim remains competitive and continues to evolve with market needs.
    - *Placeholder for SME Verification*: Verify specific post-project plans, including marketing strategies and partnerships for commercialisation.

---

### **Action Items for SME Verification**:
- Confirm any additional team members and their roles.
- Verify recruitment plans and EDI practices.
- Identify specific subcontractors and confirm their roles.
- Refine post-project commercialisation strategies and timeline.

---

This outline provides a structured response to the project delivery question while ensuring verification and refinement of certain elements before submission.
`)}
                    </div>
                </AccordionItem>

                <AccordionItem>
                    <span slot="header">Value for money</span>
                    <div class="max-w-full prose">
                        {@html marked(`
Here is the structured outline for **Question 9: Value for money** for the Innovate UK Smart Grant application, ensuring all necessary details are addressed. Any assumptions or placeholders that need to be verified with the SME are clearly highlighted.

---

### **Question 9: Value for Money**
**How will you spend your grant funding and how does this represent good value for money for the taxpayer?**

---

1. **Funding Breakdown and Distribution**  
    - **Allocation of Grant Funding**: 
        - A significant portion of the funding will be allocated to further development of the SmartClaim platform, specifically focusing on enhancing AI capabilities, integrating advanced machine learning models, and improving security features【4†source】.
        - Additional costs will include user onboarding, customer support setup, and marketing strategies to facilitate scaling and commercialisation.
        - **Distribution Between Consortium Partners (if applicable)**: Since the project is primarily driven by SmartClaim’s internal team and subcontractors, the majority of the funding will stay within the organisation. External partners may be involved in niche tasks such as AI model testing and security audits, ensuring highly specialised knowledge is employed where necessary【4†source】.  
        - *Placeholder for SME Verification*: Confirm specific roles and distribution of funds between any consortium partners or subcontractors if applicable.

2. **Cost Breakdown Aligned with Work Packages**
    - **Work Package Breakdown**:
        - **Work Package 1: AI Development** – X% of the budget allocated to developing new AI features, data processing algorithms, and machine learning models【4†source】.
        - **Work Package 2: User Interface and Experience Improvements** – Y% of the budget allocated to improving the platform’s UI/UX to ensure usability for R&D consultants.
        - **Work Package 3: Market Expansion and Customer Onboarding** – Z% of the budget for marketing, user support, and onboarding to support scalability and increase the platform’s user base.  
        - **Work Package 4: Security and Compliance** – Allocation for external security audits and compliance testing to ensure that the platform meets UK and international data protection laws【4†source】.
    - **Value for Money Calculation**: Costs will be calculated based on market-standard quotations for AI development, security audits, and marketing activities. Where possible, multiple quotations from subcontractors will be obtained to ensure competitive pricing.
    - *Placeholder for SME Verification*: Ensure all costs are benchmarked against industry standards to demonstrate best value for money, and confirm the allocation percentages.

3. **Funding Contributions from the Organisation and Partners**
    - **SmartClaim Contribution**: The company will self-fund a portion of the project through retained earnings and reinvestment from the pilot phase. This funding will be used for internal staffing costs and minor hardware upgrades【4†source】.  
    - **External Partner Contributions (if applicable)**: If any consortium partners or external contributors are involved, they will fund their own respective costs related to specialised services such as AI development or security.
    - **Funding Readiness**: All financial contributions will be in place prior to the project’s start, ensuring compliance with the latest possible start date stated in the competition's eligibility criteria.
    - *Placeholder for SME Verification*: Verify the financial readiness of all partners and confirm the precise funding breakdown.

4. **Subcontractor Costs and Value for Money**
    - **Subcontractor Tasks**: AI model training and security compliance audits will be subcontracted to external experts. These tasks require highly specific technical skills that are not available in-house【4†source】.
    - **Cost Calculations**: Subcontractor costs will be based on market rates, with competitive quotations obtained to ensure the best value. The company will evaluate quotes from at least three providers before confirming final contracts.
    - *Placeholder for SME Verification*: Confirm subcontractor roles and ensure that appropriate cost comparison and justifications are documented.

5. **Post-Project Funding and Resources for Commercialisation**
    - **Post-Project Funding Plan**: After the project’s completion, SmartClaim will continue to reinvest revenue from early adopters and pilot users to fund the final stages of commercialisation【4†source】. Additional funding sources may include private investment rounds or government-backed innovation loans to fuel expansion into international markets.
    - **Resource Allocation for Post-Project Growth**: The company’s post-project activities, such as scaling the platform to larger consultancy firms and expanding internationally, are already planned and partially resourced. The commercialisation strategy includes long-term customer support, continuous feature improvements, and marketing.
    - *Placeholder for SME Verification*: Verify any additional sources of post-project funding or private investment plans.

6. **Wider Support Needed for Project Success**
    - **Partnerships**: Strategic partnerships with consultancy networks, tax advisory groups, and legal experts will be necessary to navigate the regulatory environment in new markets【4†source】.
    - **Private Finance**: The company may seek private equity or venture capital funding to further accelerate growth and support long-term international expansion plans.
    - **Export Advice**: Given the potential to scale into international markets with similar tax credit regimes, the company will seek export advice and support from trade associations and government agencies (e.g., Department for International Trade).
    - *Placeholder for SME Verification*: Confirm any planned partnerships and funding sources, as well as any export advice requirements.

---

### **Action Items for SME Verification**:
- Confirm specific cost allocations, including distribution between consortium partners or subcontractors.
- Ensure value for money through market-standard quotations and benchmarking.
- Verify financial readiness of all project participants, including internal and external partners.
- Confirm plans for private finance or export advice needed to support project commercialisation.

---

This outline provides a strong foundation for articulating a clear and detailed response to the value for money question, ensuring that costs are justified and aligned with the project’s goals while promoting responsible use of taxpayer funding.
`)}
                    </div>
                </AccordionItem>

            </Accordion>



            <div class="mt-5 flex justify-end"></div>
        </Card>
    </div>
</div>
